
<app-not-refresh-appbar  parentLink="/profile/me-page" [rootLevel]=1>{{'feedbackTap' | translate }}</app-not-refresh-appbar>

<!-- App Capsule mt-2 mb-2 pt-2 pb-2-->

    <div class="container">
        <div class="row">
            <form class="needs-validation" novalidate>    
              
                <div class="form-group boxed">
                    <div class="input-wrapper">                        
                        <input type="text" class="form-control" id="name5" placeholder="{{ 'feedbacktitleHint' | translate}}"
                        [(ngModel)]="feedbackModel.title"  [ngModelOptions]="{standalone: true}" >
                    </div>
                </div>
               
                <div class="form-group boxed">
                    <div class="input-wrapper">      
                        <textarea class="form-control" id="name5" id="" cols="100" rows="10" required
                        [(ngModel)]="feedbackModel.description"  [ngModelOptions]="{standalone: true}" > {{ 'feedbackbodyHint' | translate}}</textarea>                  
                    </div>
                </div>
                
            <div class = 'row'>
                <div class="col-3">
                </div>
                  <div class="col-5">
                      <button class="btn btn-secondary btn-block" type="submit" [routerLink]="['/profile/me-page']" replaceUrl="true"
                     >{{'cancel' | translate}} </button>
                  </div>

                  <div class="col-4">
                      <button class="btn btn-secondary btn-block" type="submit" (click) = "Send()">{{'send' | translate}}</button>
                  </div>
            </div>
            </form>
        </div>
    </div>

