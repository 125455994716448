<app-not-refresh-appbar  parentLink="/profile/me-page" [rootLevel]=1>{{'your_referal_code' | translate }}</app-not-refresh-appbar>

<div class="main__wrapper" id="appCapsule">
    <div class="container">
        <div class="row">
            <div class="col-12 text-center">
                <img src="assets/img/money.png" alt="pendingimage" width="100px" height="100px">
                <p class = "pendingText">{{'your_referal_code' | translate }} </p>
                <h2 class ="ml-2">{{ userProfileModel?.referral_code }}</h2>
            </div>
        </div>
        <div class="row">
            <div class="col justify-content-center text-center">
             <h4 class="service-color">{{ 'ref_code_desc_invite' | translate}} </h4>
             <div class="col justify-content-center text-center">
                <input (keyup.enter)="enter($event)" type="text" class="form-control refCode" 
                placeholder="{{ 'enterRef' | translate}}" [(ngModel)]="refModel.referral_code" 
                [ngModelOptions]="{standalone: true}" >
             </div>
            </div>  
        </div>
        <div class="row justify-content-center my-4">
            <button type="button" class="button" (click) = "saveInviteCode()"  
            style="width: 80%">
               {{ 'next' | translate}}
            </button>
        </div>
    </div>
        
  
    
</div>