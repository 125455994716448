


<div class="container-fluid" style="width: 100% !important">
    <div class="row justify-content-center pb-1">
      
        <img [src]="gameObj.iconurl" alt="" width="100px" style="border-radius: 10px;">
       
    </div>
    <div class="row justify-content-center">
        <h5>{{changeLanguage()}}</h5>
    </div>
    <div class="container" style="justify-content: center !important;">
        <div class="row">
            <div class="col-8 d-flex">
                <img src="assets/img/game_kyat.png" class="game-win-lose-img" alt="" width="20px" height="20px">&nbsp;<h5 class="game-amount" style="font-weight: 600;">{{ 'before-game-balance' |translate}}({{ 'ks' |translate}})</h5>
            </div>
            <div class="col-4"> <h4 class="game-amount">{{ closeGameBalance.balanceBefore }}</h4></div>
        </div>
        <div class="row">
            <div class="col-8 d-flex">
                <img src="assets/img/game_wallet.png" alt="" width="20px" height="20px">&nbsp;<h5 class="game-amount" style="font-weight: 600;">{{ 'leave-post-game-balance' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
            </div>
            <div class="col-4"> <h4 class="game-amount">{{ closeGameBalance.postBalance }}</h4></div>
        </div>
        <div class="row">
            <div class="col-8 d-flex">
                <img src="assets/img/game_kyat.png" alt="" width="20px" height="20px">&nbsp;
                <h5 style="font-weight: 600;" class="game-amount" *ngIf="(closeGameBalance.balanceBefore - closeGameBalance.postBalance) &lt; 0">{{ 'game-win' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
                <h5 style="font-weight: 600;" class="game-amount" *ngIf="(closeGameBalance.balanceBefore - closeGameBalance.postBalance) &gt; 0">{{ 'game-lose' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
                <h5 style="font-weight: 600;" class="game-amount" *ngIf="(closeGameBalance.balanceBefore - closeGameBalance.postBalance) == 0">{{ 'game-even' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
            </div>
            <div class="col-4"><span class="game-amount" *ngIf="(closeGameBalance.balanceBefore - closeGameBalance.postBalance) &gt; 0" >(-)</span><span class="game-amount" *ngIf="(closeGameBalance.balanceBefore - closeGameBalance.postBalance) &lt; 0"> (+)</span> <span class="game-amount"> {{changeNumber(closeGameBalance.balanceBefore, closeGameBalance.postBalance) }}</span></div>
        </div>
    </div>
</div>