import { Component, EventEmitter, Injectable, Input, Output } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { LocalStorageService } from "ngx-webstorage";
import { NavigationService } from '../../../../service/navigation.service'
@Component({
  selector: 'headerbar',
  templateUrl: './headerbar.component.html',
  styleUrls: ['./headerbar.component.scss']
})
export class HeaderbarComponent {
  @Output() myEvent = new EventEmitter();
  @Output() addbtnEvent=new EventEmitter();
  @Input() rootLevel = 1;

  @Input() parentLink: string;
  
  @Input() titleonly:boolean=true;
  @Input() customright:boolean=false;

  isClose = false;
  constructor(private router: Router, private storage: LocalStorageService,
    private navigation: NavigationService) {

  }

  ngOnInit(): void {
    this.isClose = true;
  }

  refreshPage(): void {
    this.ngOnInit();
    this.myEvent.emit();

    //console.log('reload button')
  }
  back(): void {
    this.navigation.back()
  }

  withdrawAdd(){
    console.log('reach')
    this.addbtnEvent.emit(true);
  }

}