<a class="item">
    <div class="item__content">
        <div class="imageWrapper">
            <img [src]="iconUrl" alt="image" class="imaged w64">
        </div>
        <div class="in">
            <div>
                <ng-content></ng-content>
            </div>
                <ng-content select="[badge]"></ng-content>
        </div>
    </div>

    <ng-container *ngIf="isForward==true">
        <ion-icon name='chevron-forward-outline'></ion-icon>
    </ng-container>

</a>