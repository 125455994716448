import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// profile
import {
  ProfileDetailPageComponent,
  LogoutComponent
} from "@components/profilepages";

// games pages
import {
  GameListComponent,
  GameWinLosePageComponent
} from '@components/gamepages/index';
import { PageNotfoundComponent } from './component/page-notfound/page-notfound.component';
import { CountryBlackListComponent } from './component/country-black-list/country-black-list.component';
import { CommonModule } from '@angular/common';
import { OtpPageComponent } from './component/otp-page/otp-page.component';
import { PasswordResetComponent } from './component/password-reset/password-reset.component';
import { NotiDetailWithoutIdComponent } from './component/noti-detail-without-id/noti-detail-without-id.component';
import { SafariBlockPageComponent } from './component/safari-block-page/safari-block-page.component';
import { HomeComponent } from '@baseUrl/src/app/component/homepages/home/home.component';
import { SystemMainteneceComponent } from '@baseUrl/src/app/component/system-maintenece/system-maintenece.component';
import { StreamLiveComponent } from './component/stream-live/stream-live.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  //channels
  // {
  //   path: "channels",
  //   loadChildren: () => import('@components/chatting/chatting.module').then((m) => m.ChattingModule)
  // },
  {
    path: 'game-play', component: GameWinLosePageComponent
  },

  {
    path: 'noti-direct-show/:data', component: NotiDetailWithoutIdComponent
  },
  {
    path: "account",
    loadChildren: () => import('@components/signin-upPages/account.module').then((m) => m.AccountModule)
  },
  {
    path: "dashboard",
    loadChildren: () => import('@components/homepages/home.module').then((m) => m.HomeModule)
  },
  {
    path: "d-page",
    loadChildren: () => import('@components/dpages/dpage.module').then((m) => m.DpageModule)
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'home/:deviceId', component: HomeComponent,
  },
  {
    path: 'home/:deviceId/:fcmToken', component: HomeComponent,
  },
  {
    path: 'home/:deviceId/:fcmToken/:chkVersion', component: HomeComponent,
  },
  {
    path: "service",
    loadChildren: () => import('@components/servicepages/service.module').then((m) => m.ServiceModule)
  },
  {
    path: "promotion",
    loadChildren: () => import('@components/promotionpages/promotion.module').then((m) => m.PromotionModule)
  },
  {
    path: "profile",
    loadChildren: () => import('@components/profilepages/profile.module').then((m) => m.ProfileModule)
  },
  {
    path: "wallet",
    loadChildren: () => import('@components/walletpages/wallet.module').then((m) => m.WalletModule)
  },
  {
    path: "game",
    loadChildren: () => import('@components/gamepages/game.module').then((m) => m.GameModule)
  },
  {
    path: "agent",
    loadChildren: () => import('@components/agents/agents.module').then((m) => m.AgentsModule)
  },
  {
    path: "level",
    loadChildren: () => import('@components/levelpages/levelpages.module').then((m) => m.LevelpagesModule)
  },
  {
    path: 'gameList/:providerId', component: GameListComponent
  },
  {
    path: 'country-black-list', component: CountryBlackListComponent
  },

  {
    path: 'otp', component: OtpPageComponent
  },

  
  // {
  //   path: 'stream', component: StreamPageComponent
  // },
  {
    path: 'stream', component: StreamLiveComponent
  },
  

  {
    path: 'resetPassword', component: PasswordResetComponent
  },

  {
    path: 'profile-detail', component: ProfileDetailPageComponent
  },  
  {
    path: 'logout', component: LogoutComponent
  },
  {
    path: 'logout/:deviceId', component: LogoutComponent
  },
  {
    path: 'safari-block', component: SafariBlockPageComponent
  },
  {
    path: 'sytem-maintence/:message', component: SystemMainteneceComponent
  },
  {
    path: '**', component: PageNotfoundComponent
  }

];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }