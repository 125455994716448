<appbar customright="true" (addbtnEvent)="withdrawAdd()">
    {{'twod_confirm_page' | translate }}</appbar>
<div id="appCapsule">

    <!-- * App Header -->
    <div class="card__wrapper" *ngFor="let bankAccObj of mywithdrawalBankAccList">

        <img [src]="bankAccObj.imageUrl" alt="product" class="imaged">

        <div class="info__content">
            <p class="card-text">{{bankAccObj.account_number}}</p>

            <div class="btn-action-group">
                <button type="button" class="btn btn-edit" *ngIf="bankAccObj.payment_id==4 && bankAccObj.kbz_withdrawqrcode==null"
                    (click)="onQREdit(qrEdit,bankAccObj)">
                    {{ 'edit' | translate}}
                </button>
                <button type="button" class="btn btn-delete"
                    (click)="withdrawDelete(bankAccObj.payment_id,bankAccObj.bank_account_id)">
                    {{ 'withdraw_delete' | translate}}
                </button>
            </div>

        </div>
    </div>
    <ng-template #qrEdit>
        <div class="modal-header">
                    <h4 class="modal-title pull-left">KBZ Pay QR Upload</h4>
                    <button type="button" class="close pull-right" aria-label="Close" (click)="modalClose()">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
        <div class="modal-body">
            <app-qr-reader (onQRValueEmit)="onQRValueEmit($event)"></app-qr-reader>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="onUpdateBankAccount()">
            {{ 'withdrawal_confrim' | translate}}
        </button>
        </div>
      </ng-template>

</div>

<ngx-spinner name="loadingSubmiting" bdOpacity=0.9 size="medium" color="#00acc1" type="ball-spin-clockwise"
    [fullScreen]="true"></ngx-spinner>

<!-- <app-navigation-bar></app-navigation-bar> -->