    <!-- App Header -->
    <div class="appHeader text-light">
        <div class="left">
            <ng-content select="[btnDownloadApp]"></ng-content>
        </div>
        <div class="pageTitle"><ng-content></ng-content></div>
        <div class="right">           
            <a class="headerButton">  
                <ion-icon name="refresh-outline" (click) = "refreshPage()"></ion-icon>             
            </a>
           
        </div>
    </div>
    <!-- * App Header -->