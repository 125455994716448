import { Component, Input, OnInit } from '@angular/core';
import isUAWebview from "is-ua-webview";
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.scss']
})
export class ContactBoxComponent implements OnInit {
  @Input() contactNo: string;
  @Input() viberNo: string;

  deviceId: any;
  isWebview: boolean;
  openUrl: string;
  chkVersion: any;

  constructor(private storage: LocalStorageService,) {
    this.isWebview = isUAWebview(navigator.userAgent);
    this.chkVersion = this.storage.retrieve('localChkVersion');

    if (this.deviceId != null || this.isWebview) {
      if (this.chkVersion != null) {
        this.openUrl = "openinnewtap";
      }
      else {
        this.openUrl = "";
      }
    }
    else {
      this.openUrl = "";
    }
  }
  public isIOS:boolean=false;

  ngOnInit(): void {
    if([
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
      'MacIntel'
    ].includes(navigator.platform)) this.isIOS=true;
  }

}
