<!--Hey! This is the original version
of Simple CSS Waves-->
<div id="appCapsule">
  <div class="header">
    <!-- App Header -->
    <app-page-header>
      <div class="app-download" (click)="downloadPage()" btnDownloadApp>
        <div style="display: flex;">
          <h4 style="color: #fff;">App</h4>
          <ion-icon name="download-outline" style="font-size: 25px;"></ion-icon>
        </div>
      </div>
      {{ 'helloword' | translate }}
    </app-page-header>


    <!--Content before waves-->
    <app-account-login></app-account-login>

    <div>
      <app-ads-slider></app-ads-slider>
    </div>

    <!--Waves Container-->
    <!-- <div>
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
        <defs>
          <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
        </defs>
        <g class="parallax">
          <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
          <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
          <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
          <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
        </g>
      </svg>
    </div> -->
    <!--Waves end-->

  </div>
  <!--Header ends-->


  <!-- App Capsule -->
  <div class="pt-0">
    <div class="row">      
      <div class="col-4 px-2 py-2">
        <div class="hex-main" (click)="twodPage()">
          <div class="icon__box" style="background-image: url(assets/img/homeicon/2d.png);">
          </div>
        </div>
        <h3 class="text-color text-center">2D</h3>
      </div>
      <div class="col-4 px-2 py-2">
        <div class="hex-main" (click)="threedPage()">
          <div class="icon__box" style="background-image: url(assets/img/homeicon/3d.png);">
          </div>
        </div>
        <h3 class="text-color text-center">3D</h3>
      </div>
      <div class="col-4  px-2 py-2" *ngFor="let gameProviderObj of gameProviderList">


        <div class="hex-main">
          <div class="icon__box" [style.background-image]="'url('+gameProviderObj.imageUrl+')'"
            (click)="goToGame(gameProviderObj.id)">
          </div>
        </div>

        <h3 class="text-color text-center">
          {{gameProviderObj.display_name}}</h3>
      </div>
    </div>
    <!-- channels -->
    <!-- <div class="row" [routerLink]="['/channels']" *ngIf="isUserLoggedIn">
      <div class="col-12">
        <div class="sticky-div">
          <ion-icon name="mail-open-outline"></ion-icon>
        </div>        
      </div>   
    </div> -->

  </div>

  
  <!-- * App Capsule -->
  <app-navigation-bar></app-navigation-bar>


  <ngx-spinner name="refreshLoading" bdOpacity=0.9 size="medium" color="#00acc1" type="ball-spin-clockwise"
    [fullScreen]="true"></ngx-spinner>


</div>