import { Component, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

// external import
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

// rxjs
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';

// services
import { FunctService, UtilService, DtoService } from '@services/index';

@Component({
  selector: 'app-withdraw-change-account',
  templateUrl: './withdraw-change-account.component.html',
  styleUrls: ['./withdraw-change-account.component.scss']
})
export class WithdrawChangeAccountComponent implements OnInit {
  token: any;
  mywithdrawalBankAccList: any;
  withdrawalBankAccDelete: any;
  constructor(private modalService: BsModalService, private translateService: TranslateService, private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService,
    private router: Router, private storage: LocalStorageService, private funct: FunctService, private location: Location,) {
   
  }

  ngOnInit(): void {
    this.getMyWithdrawAccounts();
    this.withdrawalBankAccDelete = {
      "payment_id": 0,
      "bank_acc_id": 0
    }
  }

  handleError(error: HttpErrorResponse) {
    this.spinner.hide('loadingSubmiting');
    if (error.status == 0) {
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }

    if (error.status == 423) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 406) {
      this.toastr.error("", this.translateService.instant("withdraw_delete_fail"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }
    return throwError(error);
  }
  withdrawAdd() {    
    this.router.navigate(['/wallet/withdraw', 'add'], { replaceUrl: true })
  }
  getMyWithdrawAccounts() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'userbankaccount/getuserbankaccount-byUserId', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;
          this.mywithdrawalBankAccList = this.dto.Response;
          this.spinner.hide('loadingSubmiting');
        }
      );
  }

  withdrawDelete(payment_id: any, bank_acc_id: any) {
    this.withdrawalBankAccDelete.payment_id = payment_id;
    this.withdrawalBankAccDelete.bank_acc_id = bank_acc_id;

    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.post(this.funct.ipaddress + 'userbankaccount/deleteuserBankAccount', this.withdrawalBankAccDelete, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;
          if (this.dto.Response.status == "Success") {
            this.getMyWithdrawAccounts();
            return;
          }

        }
      );

  }

  modalRef: BsModalRef; 
  qrValue:string;
  selectedBank:any;
  onQREdit(qrEdit: TemplateRef<any>,bankObj) {
   this.selectedBank=bankObj;
    this.modalRef = this.modalService.show(qrEdit, {
      class: "twodBetEdit-class modal-sm"
    });
  }

  onQRValueEmit(qrValue:any){
    this.qrValue=qrValue.qrValue;
  }

  onUpdateBankAccount(){
    this.spinner.show('loadingSubmiting');
    var secretKey = this.funct.secretKey;
    console.log(secretKey)
    var text = this.selectedBank.payment_id + this.selectedBank.account_number + secretKey;
    let finalModal={
      "bank_account_id":this.selectedBank.bank_account_id.toString(),
      "payment_id": this.selectedBank.payment_id,
      "account_number": this.selectedBank.account_number,
      "signature": this.funct.getSignature(text),
      "kbz_withdrawqrcode": this.qrValue
    }
    console.log(this.selectedBank)
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.post(this.funct.ipaddress + 'userbankaccount/insertkpayqr', finalModal,{ headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          console.log(result);
          this.modalClose();
          this.getMyWithdrawAccounts();
        }
      );
  }
  modalClose(){
    this.modalRef.hide();
    this.qrValue="";
    this.selectedBank={};
  }

}


