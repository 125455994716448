<ng-container *ngIf="isOtpService==false">
    <app-not-refresh-appbar >OTP {{formPage}}</app-not-refresh-appbar>
    <!-- App Capsule -->
    <div id="appCapsule" class="main__adjestment">
        <div class="section-title register-title mb-2">{{ 'otpdescription' | translate}}</div>
        <div class="section-title register-title mb-2"> {{phoneNumber}} </div>
        <div class="section-title mb-2">{{ 'otptitle' | translate}}</div>
        <form>
            <code-input [isCodeHidden]="false" [codeLength]="6" (codeChanged)="onCodeCompleted($event)"
                (keyup.enter)="validateOtp()"></code-input>
            <p id="passErr" class="error-color" style="text-align: center; width: 100%;"></p>

            <div class="counterTimer" *ngIf="counterTimer >0">{{counterTimer}}</div>
            <a class="btn btn-text-primary float-right" (click)="actionType == 'NEWDIVICE'?getNewOtp():getOtp()"
                *ngIf="counterTimer == 0">Resend OTP</a>

            <div class="row justify-content-center mt-5">
                <button type="button" class="btn btn-primary"
                    (click)="actionType == 'NEWDIVICE'? getNewDeviceOtp(): checkOtp()">{{ 'next' | translate}}</button>
            </div>
        </form>
        <div class="err__msg mt-4">
            <div (click)="goToServicePhone()" class="text__link"> {{'code_err_msg' | translate}} {{'help' | translate}}</div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="isOtpService==true">
    <app-otp-service-phone (backemit)="showOptPage($event)"></app-otp-service-phone>
</ng-container>


<!-- * App Capsule -->
<!-- 
    <ngx-spinner bdColor = "rgb(6,56,107)" size = "large" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner> -->