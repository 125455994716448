import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from "@angular/router";
import { LocalStorageService } from 'ngx-webstorage';
 
@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private history: string[] = []; 
  public currentRoute: string="";
  constructor(private router: Router, private location: Location,
    private storage: LocalStorageService, ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        this.currentRoute = event.url; 
        // let customBack = this.storage.retrieve('customBack');
        // console.log('back>>'+ customBack);
        // console.log(this.currentRoute)    
        //transaction waiting by pyae 
        // if(this.currentRoute == customBack){          
        //   this.storage.clear('customBack');
        //   this.location.back();
        //   //this.location.back();
        // }
      }
    });
  }
 
  back(): void { 
    
    this.history.pop();
    if (this.history.length > 0) {
      this.location.back();
    } else {
      this.router.navigateByUrl("/");
    }
    
  }
}