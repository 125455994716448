import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// rxjs
import 'rxjs/add/operator/map';
// library
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class AppNavigationBarComponent implements OnInit {
  promotionCount : any;
  promotionList : any;
  token : any;
  routerName: any;
  isUserLoggedIn: any;
  deviceId: any;

  constructor(
    private router: Router, 
    private storage: LocalStorageService,) 
    {
      this.deviceId=this.storage.retrieve('localDeviceId'); 
    }
  ngOnInit(): void 
  {
   
    this.isUserLoggedIn= this.storage.retrieve('isUserLoggedIn');
    this.routerName = this.router.url;
    this.promotionCount = this.storage.retrieve('localpromotionCount');
    // this.getAllPromotions();
  }

  openPage(routeUrl:string) {   
   //alert(routeUrl)
    if(routeUrl =='/home'){  
      if(this.deviceId != null){       
        this.router.navigate([routeUrl,this.deviceId]); 
        return;
      } 
      else{
        this.router.navigate([routeUrl]); 
        return;
      }    
    }
    this.router.navigate([routeUrl]);    
}



 
}
