import { Component, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login-device-dialog',
  templateUrl: './login-device-dialog.component.html',
  styleUrls: ['./login-device-dialog.component.css']
})
export class LoginDeviceDialogComponent implements IModalDialog {
  private internalActionButtons = []; 
  constructor(private translateService: TranslateService,private storage: LocalStorageService,private router: Router,){

  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<string>>) {
    options.actionButtons = this.internalActionButtons;

    this.internalActionButtons.push({
      text: this.translateService.instant('login_no'),
      buttonClass: 'btn',
      onAction: () => true     
    });

    this.internalActionButtons.push({
      text: this.translateService.instant('login_yes'),
      buttonClass: 'btn',      
      onAction: () => this.otpNextPage()
    });
  }
  otpNextPage(){  
    this.router.navigate(['/account/login-verify-phone'], {state: {actionType: 'NEWDIVICE', phoneNumber: this.storage.retrieve('localLoginModel').phone_no}} )

  }

}