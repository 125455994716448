import { Component, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions, ModalDialogOnAction } from 'ngx-modal-dialog';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { data } from 'jquery';

@Component({
  selector: 'app-gamewinlose',
  templateUrl: './gamewinlose.component.html',
  styleUrls: ['./gamewinlose.component.scss']
})
export class GamewinloseComponent implements IModalDialog {

  private internalActionButtons = []; 
  closeGameBalance: any;
  gameObj: any;
  
  constructor(private translateService: TranslateService,private storage: LocalStorageService,private router: Router,){
    this.closeGameBalance=this.storage.retrieve('localCloseGameBalance');
    this.gameObj=this.storage.retrieve('localGameIcon');
  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<string>>) {
    options.actionButtons = this.internalActionButtons;
  
    //this.topup=this.storage.retrieve('localTopupAlertData');
    
    this.internalActionButtons.push({
      text: this.translateService.instant('ok'),
      buttonClass: 'btn text-danger game-close',
      onAction: () => true  ,
     
    });
  
    // this.internalActionButtons.push({
    //   text: this.translateService.instant('login_yes'),
    //   buttonClass: 'btn btn-success',    
    //   onAction: () => true   
    // });
  }
  nextPage(){  
    this.router.navigate(['/wallet/top-up'])
  }
  
  changeNumber(n1,n2){    
   return  Math.abs(n1 - n2); 
  }
  changeLanguage() {
    var data=this.gameObj;
    // console.log(JSON.stringify(this.gameList))
     let language= this.storage.retrieve('localLanguage');
     if (language == "my") {
       return data.name_my != null ? data.name_my : data.name;
     } else if (language == "th") {
       return data.name_zh != null ? data.name_zh : data.name;
     } else if (language == "zh") {
       return data.name_zh != null ? data.name_zh : data.name;
     } else {
       return data.name;
     }
   }
}