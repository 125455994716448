import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-safari-block-page',
  templateUrl: './safari-block-page.component.html',
  styleUrls: ['./safari-block-page.component.scss']
})
export class SafariBlockPageComponent implements OnInit {
  parentLink: any;

  constructor() {
    this.parentLink=history.state.parentLink;
   }

  ngOnInit(): void {
  }

}
