import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { BehaviorSubject } from 'rxjs';
import { FunctService } from './funct.service';
import { Message } from '../models/message';
import { ApiTokenService } from './api-token.service';


@Injectable({
  providedIn: 'root'
})
export class ChatSignalrService { 
     
  public connection : signalR.HubConnection;
  public messages$ = new BehaviorSubject<Message[]>([]);
  public connectedUsers$ = new BehaviorSubject<string[]>([]);
  public messages: Message[] = []; 
  public users: string[] = [];
  newMessage: Message;
  public signalrConnect: any;
  public data: any;

  constructor(public api: FunctService,private apiTokenServer: ApiTokenService,
    ) {
   
  }
  
  
  private hubConnection: signalR.HubConnection
  public startConnection = () => {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.api.chatSignalrConnect)
      .build();

   return this.hubConnection
      .start()
      .then(() => {
        // console.log(this.hubConnection.connectionId);
        // console.log(this.hubConnection.state);
        //onsole.log("connected");
       // this.hubConnection.invoke("ConnectGroup",this.hubConnection.connectionId,"9");

       //get chat history
        this.gethistory();
        return this.hubConnection.state==="Connected";

      })
      .catch(err =>{ console.log('Error while starting connection: ' + err); return false});
  }

  public ChatListener = (groupid, fn) => {
     var methodname = "ReceiveMessage" + groupid;
     //console.log("Mehtod Name :" + methodname);     
    this.hubConnection.on(methodname, (data) => {
      //console.log("Receive : " +data);
      var mess = JSON.parse(data);
      this.messages = [...this.messages, mess];  
      this.messages$.next(this.messages);    
      
      
      if(fn){
        fn(data);
      }
      //this.data = JSON.parse(data);
      
    });
  }

  // Send Messages
  public async sendMessage(message: any){
    return this.hubConnection.invoke("SendMessage", message)
  }

   //leave
   public async leaveChat(){
    return this.hubConnection.stop();
  }


  public async gethistory(){
    this.apiTokenServer.getChat(`Chat/getchathistory?group_id=${localStorage.getItem('groupId')}&groupname=${localStorage.getItem('groupName')}`) 
    .then(async response=> {       
       if(response.status == 200){
        var history = response.data;  
        for(let i=0;i< history.length;i++){     
          if(history[i].Type == 'GIFT')  {
            const decryptedData = await this.api.decrypt(history[i].Content);
            const splitData= decryptedData.split("send=")[1];
            const sendDate= new Date(splitData.split("&exp=")[0]);
            const splitDataexp= splitData.split("&exp=")[1];
            const expDate = new Date(splitDataexp.split("&id=")[0]);
            const currentDate= new Date();
            if(expDate.getFullYear() == expDate.getFullYear() && expDate.getMonth() <= currentDate.getMonth() && 
            expDate.getDate() <= currentDate.getDate() && expDate.getHours() <= currentDate.getHours() && 
           (expDate.getHours() == currentDate.getHours() && expDate.getMinutes() <= currentDate.getMinutes())) {
              this.messages.push({...history[i], ...{isGift: true}});
            }
            else  this.messages.push({...history[i], ...{isGift: false}});       
          }           
          else  this.messages.push({...history[i], ...{isGift: false}});        
        }          
        //this.messages =response.data;  
        //console.log(this.messages);
        this.messages$.next(this.messages);  
       }             
         
    })          
    .catch(async error=> {    
         
      return false;
    });   

  }


 
    

}
    
    