<!-- App Capsule  -->

<div class="container-fluid" *ngIf="!isUserLoggedIn">
  <div class="row align-items-center">
    <div class="col-8">
      <a (click)="loginAccount()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
        <div class="d-flex align-items-center">
          <ion-icon name="person-circle-outline" class="account-login">
          </ion-icon>
          <span class="account_login_text">{{ 'click_to_login' | translate}}</span>
        </div>
      </a>
    </div>
    <div class="col-2">
      <app-language-page></app-language-page>
    </div>
    <div class="col-1">
      <a (click)="goToNotiList()">
        <div>
          <ion-icon name="notifications-outline" style="font-size: 30px;top: 20px;left:0px;"></ion-icon>


        </div>
        <span *ngIf="notiCount !=0" class="noti__icon--content">
          <h5 style="font-size:8px;color:white;">
            {{notiCount}}
          </h5>
        </span>
      </a>
    </div>
  </div>


</div>


<div class="wallet-account" *ngIf="isUserLoggedIn">

  <div class="container-fluid account-bgcolor pb-2">

    <div class="row mb-2">
      <div class="col-8 text-left">
        <div class="wallet-account">
          <a (click)="gooProfileDetail()">
            <div *ngIf="userProfileModel.imageUrl == null || userProfileModel.imageUrl == ''">
              <ion-icon name="person-circle-outline" class="account-login-active py-2"></ion-icon>
            </div>
           
            <div *ngIf="userProfileModel.imageUrl != null" class="profile__img">
              <img [src]="userProfileModel.imageUrl" >
            </div>
          </a>
          <div class="info__wrapper">
            <h4 class="account-title-name open-wallet" (click)="openWalletPage()">{{ changeName() }}</h4>
            <h5 class="phone__number" *ngIf="userProfileModel.phone_no">{{checkPhoneNumber()}}</h5>
          </div>
        </div>
      </div>

      <div class="col-2 text-left" style="padding-top: 15px;">
        <app-language-page></app-language-page>
      </div>

      <div class="col-1" style="padding-top: 20px;">
        <a (click)="goToNotiList()">
          <div>
            <ion-icon name="notifications-outline" style="font-size: 30px;top: 20px;left:0px;"></ion-icon>

            <!-- *ngIf="notiCount>0" -->
            <!-- <span 
            style="top: 15px;left:30px; position:absolute;background:red;width: 20px;height: 20px;border-radius: 50%">
            <span style="font-size:16px;color:white;">
  
            </span>
          </span> -->
          </div>
          <span *ngIf="notiCount !=0" class="noti__icon--content">
            <h5 style="font-size:8px;color:white;">
              {{notiCount}}
            </h5>
          </span>
        </a>
      </div>
    </div>
    <div class="amount__wrapper">
      <div class="icon__wrapper">
        <ion-icon name="wallet" class="active-wallet"></ion-icon>
        <p class="wallet__label">
          {{'balance' | translate}}
        </p>
      </div>
      <div class="d-flex align-items-center">
        <span class="text-eye">
          <a [routerLink]="" (click)="showPassword(!showAmount)">
            <ion-icon [attr.name]="showAmount ? 'eye' : 'eye-off' "
              style="color: #ffffff !important; margin-right: 8px; font-size: 18px;"></ion-icon>
          </a>
        </span>
        <h3 *ngIf="showAmount==true" class="open-wallet1 open-wallet-amount" (click)="openWalletPage()">{{
          showBalance | number : '1.2-2'}}</h3>

        <h3 *ngIf="showAmount==false" class="open-wallet1 open-wallet-amount" (click)="openWalletPage()">*****
        </h3>

        <h4 class="open-wallet1 txt__currency">({{ 'ks' |
          translate}})</h4>



        <span class="wallet-right-arrow" (click)="openWalletPage()">
          <ion-icon name="chevron-forward-outline" style="font-size: 14px;"></ion-icon>
        </span>
      </div>


    </div>
  </div>

</div>
<div class="container wallet-padding-login" *ngIf="isUserLoggedIn">
  <div class="row">
  </div>
</div>