import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-header-detail',
  templateUrl: './page-header-detail.component.html',
  styleUrls: ['./page-header-detail.component.css']
})
export class PageHeaderDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
