<!-- App Header -->

<app-not-refresh-appbar>Lucky2D</app-not-refresh-appbar>
<!-- * App Header -->

<!-- App Capsule -->
<div id="appCapsule">
    <div class="row listTitleImg"><img src="./assets/img/vpnImage.png" width="50px" height="50px"></div>
    <div class="listview-title mt-2">VPN ပိတ်ပြီးမှသာ Game ထဲသို့ဝင်ပါ</div>
    <ol class="listview simple-listview" *ngFor="let qmBlackListObj of this.qmBlackList; let i = index">
        <li>{{i+1}}. {{qmBlackListObj.countryName_mm}}</li>
    </ol>

</div>
<!-- * App Capsule -->
