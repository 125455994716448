import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { NavigationService } from '../../../../service/navigation.service';
@Component({
  selector: 'app-not-refresh-appbar',
  templateUrl: './not-refresh-appbar.component.html',
  styleUrls: ['./not-refresh-appbar.component.scss']
})
export class NotRefreshAppbarComponent implements OnInit {

 @Input() className:string;
  @Input() rootLevel=1;

  @Input() parentLink:string;
  @Input() typeUrl: string;  
  @Input() type: string;
 
  constructor(
    private navigation: NavigationService,
    private router: Router,private storage: LocalStorageService,) { 
   
  }

  ngOnInit(): void {
  
  }
  
  refreshPage(): void{
    this.ngOnInit();
  
  }
  back(): void {
    this.navigation.back()
  }


}