
<app-not-refresh-appbar  parentLink="/dashboard/noti-list" [rootLevel]=1>{{'notifications-details' | translate }}</app-not-refresh-appbar>


<div class="root" *ngIf="isUserLoggedIn">
    <div *ngIf="data.isFirstTopup != '1' && data.type=='TOPUP'">
        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                <span>{{getTopupNotiStr(data.state)}}</span>

            </div>
            <div class="divider">

            </div>

            <div class="date">
                {{'topup_time' | translate}} {{getDateTime(data.request_date)}}
            </div>

        </div>
        <div class="imgClass">
            <img src="assets/img/topupnoti.png" alt="image" class="imaged">
        </div>
    </div>

    <div *ngIf="data.type=='WITHDRAW'">
        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                <span>{{getWithdrawNotiStr(data.state)}}</span>

            </div>
            <div class="divider">

            </div>

            <div class="date">
                {{'withdrawal_time' | translate}} {{getDateTime(data.request_date)}}
            </div>

        </div>
        <div class="imgClass">
            <img src="assets/img/withdrawalnoti.png" alt="image" class="imaged">
        </div>
    </div>
    
    <div *ngIf="data.type=='2D Results'">
        <div class="card noti-card">
            
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">           
                <span>{{ changeResult1(data.body) }} <span class="number-color">{{ changeResult2(data.body) }}</span> </span>            
                <span>{{ 'towd_is' | translate}}</span>

            </div>
            <div class="divider">
            </div>

            <div class="date">
                {{'noti_date' | translate}} {{getDateTime(data.currentdate)}}
            </div>



        </div>
        <div class="imgClass">
            <span class="twod_image">2D</span>
            <!-- <img *ngIf="notiDetailObj.fortime == 'AM'" src="assets/img/2D_morning.jpg" alt="image" class="imaged">
            <img *ngIf="notiDetailObj.fortime != 'AM'" src="assets/img/2D_evening.jpg" alt="image" class="imaged"> -->
        </div>
    </div>


    <div *ngIf="data.type=='3D Results'">

        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                <span>{{'threed_result' | translate}}</span>
                <span class="number">{{data.number}}</span>
                <span>{{ 'towd_is' | translate}}</span>
                <!-- {{notiDetailObj.body}} -->
            </div>
            <div class="divider">

            </div>

            <div class="date">
                {{'noti_date' | translate}} {{getDate(data.currentdate)}} 3:30 PM
            </div>

        </div>
        <div class="imgClass">
            <span class="threed_image">3D</span>
            <!-- <img src="assets/img/3D.jpg" alt="image" class="imaged"> -->
        </div>
    </div>


    <div *ngIf="data.type=='All' || data.type=='Others'">

        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                {{data.body}}
            </div>
            <div class="divider">

            </div>

            <div class="date">
                {{'noti_date' | translate}} {{getDateTime(data.currentdate)}}
            </div>



        </div>
        <div class="imgClass">
            <img src="assets/img/icon.png" alt="image" class="imaged">
        </div>

    </div>

    <div *ngIf="data.type=='WINNER'">
        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                <div style="font-weight: bold;font-size: 16;">{{userProfileModel.name}}</div>

                <div *ngIf="data.category == '2D'"
                    style="padding-top: 10px;padding-bottom: 10px;font-size: 14;">
                    {{'noti_winner_title2D'| translate}}
                </div>

                <div *ngIf="data.category != '2D'"
                    style="padding-top: 10px;padding-bottom: 10px;font-size: 14;">
                    {{'noti_winner_title3D'| translate}}
                </div>

                <div style="padding-top: 10px; font-size:18px;color: blue;">
                    {{ data.balance | number : '1.2-2'}}
                </div>

            </div>

            <div class="divider">
            </div>

            <div class="row" style="justify-content:space-between;padding-top: 10px;">
                <div class="col">
                    {{'bet_time'| translate}}
                </div>
                <div class="col">
                    {{getWinnerDate()}}
                </div>
            </div>


            <div class="row" style="justify-content:space-between;padding-top: 10px;">
                <div class="col">
                    {{'noti_winner_number'| translate}}
                </div>
                <div class="col">
                    {{data.number}}
                </div>
            </div>


            <div class="row" style="justify-content:space-between;padding-top: 10px;">
                <div class="col">
                    {{'bet_amount'| translate}}
                </div>
                <div class="col">
                    {{ data.amount | number : '1.2-2'}}
                </div>
            </div>

            <div class="row" style="justify-content:space-between;padding-top: 10px;">
                <div class="col">
                    {{'odds'| translate}}
                </div>
                <div class="col">
                    {{ data.odd }}
                </div>
            </div>



            <!-- <div class="date">
            {{'point_time' | translate}} {{getDate(data.currentdate)}} 
        </div> -->

        </div>
        <div class="imgClass">
            <img src="assets/img/winnoti1.png" alt="image" class="imaged">
        </div>
    </div>

    <div *ngIf="data.type=='TOPUP' && data.isFirstTopup == '1'">
        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                <span>{{getFirstTopUpStr()}}</span>

            </div>
            <div class="divider">

            </div>

            <div class="date">
                {{'point_time' | translate}} {{getDateTime(data.currentdate)}}
            </div>

        </div>
        <div class="imgClass">
            <img src="assets/img/point.png" alt="image" class="imaged">
        </div>
    </div>

    <div *ngIf="data.type=='POINT'">
        <div class="card noti-card">
            <div class="title">
                {{data.title}}
            </div>
            <div class="body">
                <span>{{getNotiPoint()}}</span>

            </div>
            <div class="divider">

            </div>

            <div class="date">
                {{'point_time' | translate}} {{getDate(data.currentdate)}}
            </div>

        </div>
        <div class="imgClass">
            <img src="assets/img/point.png" alt="image" class="imaged">
        </div>
    </div>


</div>
<div class="root" *ngIf="!isUserLoggedIn">
    <div class="container-fluid d-flex justify-content-center">
        <div class="col-12 mt-2 ">
          <a (click)="loginAccount()" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
            <div style="text-align: center;">
               <ion-icon name="person-circle-outline" style="vertical-align:middle ;margin-right:10px; font-size: 50px;" class="account-login" >
               </ion-icon>  
              <span class="account_login_text" style="font-size: 20px !important; color: blue;">{{ 'click_to_login' | translate}}</span>
            </div>
          </a>
    </div>

</div>