import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

// library
import { CodeInputComponent } from 'angular-code-input';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

// rxjs
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';

// services
import { DtoService, FunctService, UtilService } from "@services/index";

@Component({
  selector: 'app-otp-page',
  templateUrl: './otp-page.component.html',
  styleUrls: ['./otp-page.component.scss']
})
export class OtpPageComponent implements OnInit {
  @ViewChild('codeInput') codeInput !: CodeInputComponent;
  isOtpService: boolean = false;
  OtpSms: any;
  otpcode: any;
  phoneNumber: any;

  time: number = 120;
  counterTimer;
  interval;
  otpPhoneNumber: any;
  actionType: any;// 'NEWDIVICE'
  updateDeviceId: any;
  deviceId: any;
  phone_no: any;
  ipAddress: any;
  guid: any;
  request_id: any;
  code: any;
  password: any;
  formPage: string;
  token: any;
  checkOtpModel: any;
  parentLink: any;
  constructor(private translateService: TranslateService, private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService,
    private router: Router, private storage: LocalStorageService, private funct: FunctService, private location: Location,) {

    this.actionType = history.state.actionType;
    this.formPage = history.state.formPage;
    this.parentLink = history.state.parentLink;
  
  }

  ngOnInit(): void {
    //alert( this.actionType )
    this.startTimer();
    this.updateDeviceId = {
      deviceId: '',
      phone_no: '',
      ipAddress: '',
      guid: '',
      request_id: 0,
      code: '',
    }
    if (this.storage.retrieve('localNewDeviceOtpSms') != null) {
      if (this.storage.retrieve('localNewDeviceOtpSms').number != null) {
        this.phoneNumber = "+" + this.storage.retrieve('localNewDeviceOtpSms').number;
      };

    }

    if (this.storage.retrieve('localInsertAccountOtpSms') != null) {
      if (this.storage.retrieve('localInsertAccountOtpSms').number != null) {
        this.phoneNumber = "+" + this.storage.retrieve('localInsertAccountOtpSms').number;
      }
    }

    if (this.storage.retrieve('localPhoneValue') != null) {
      var prefix = this.storage.retrieve('localPhonePrefix');
      var phone = this.storage.retrieve('localPhoneValue');
      if (phone.startsWith('0')) {
        this.phoneNumber = prefix + phone.substring(1, phone.length);
      }
      else {
        this.phoneNumber = prefix + phone;
      }

    }

    this.checkOtpModel = {
      code: '',
      request_id: '',
      phone_no: '',
      register_key: ''
    }
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.time === 120) {
        this.time--;
      }
      if (this.time === 0) {
        this.time = 0;
      } else {
        this.time--;
      }
      this.counterTimer = this.transform(this.time)
    }, 1000);
    return this.counterTimer;
  }
  transform(value: number): string {
    // const minutes: number = Math.floor(value / 120);        
    // return minutes + ':' + (value - minutes * 60);
    const seconds: number = Math.floor(value);
    return '' + (value);
  }

  onCodeCompleted(i: number) {

    this.otpcode = i;
    this.validateOtp();

  }

  validateOtp() {
    if (!this.otpcode || this.otpcode.length < 6) {
      $("#passErr").html(this.translateService.instant("otp_required"));
      return false;
    }
    else {
      $("#passErr").html("");
      return true;
    }

  }

  checkOtp() {
    
    let checkOPTINput = this.validateOtp();
    if (!checkOPTINput) {
      return;
    }
    console.log(this.actionType)
    if (this.actionType == "insertAccount") {
      
      if (this.storage.retrieve('localInsertAccountOtpSms').request_id != null) {

        this.token = this.storage.retrieve('token');
        let headers = new HttpHeaders();
        headers = headers.set('Authorization', this.token);
        this.OtpSms = [];
        this.http.get(this.funct.ipaddress + 'transaction/withdrawcheckOTP?code=' + this.otpcode + '&request_id=' + this.storage.retrieve('localInsertAccountOtpSms').request_id, { headers: headers })
          .pipe(
            catchError(this.handleError.bind(this))
          )
          .subscribe(
            result => {
              this.dto.Response = result;
              this.OtpSms = this.dto.Response;
              let bankList = this.storage.retrieve('localInsertBankBankAccount');
              bankList.otp_guid = result.otp_guid;
              console.log(bankList)
              this.storage.store('localInsertBankBankAccount', bankList);
              if (this.OtpSms != null) {
                if (this.OtpSms.status == true) {
                  this.router.navigate(['/wallet/withdraw'], { state: { otpSms: "insert" }, replaceUrl: true });
                  this.storage.clear('localInsertAccountOtpSms');
                  return true;
                } else {
                  this.toastr.error("Tip", 'OTP is not correct', {
                    timeOut: 3000,
                    positionClass: 'toast-top-center',
                  });//ShowError(context,Tran.of(context).text("sys_unauthrized"));
                  return false;
                }
              }
            }
          );

      }
      return
    }
 
    if (this.otpcode != null && this.otpcode.length == 6) {
      console.log(this.storage.retrieve('localOtpSms'))
      // alert(this.otpcode)
      // alert(this.storage.retrieve('localOtpSms').request_id)
      if (this.storage.retrieve('localOtpSms') != null) {
        if (this.storage.retrieve('localOtpSms').request_id != null) {
          let headers = new HttpHeaders();
          this.OtpSms = [];

          this.checkOtpModel.code = this.otpcode;
          this.checkOtpModel.request_id = this.storage.retrieve('localOtpSms').request_id;
          this.checkOtpModel.phone_no = this.storage.retrieve('localOtpSms').number;
          if (this.formPage == undefined) {
            this.checkOtpModel.register_key = this.funct.encrypt('checkForRegister');
          }
          else {
            this.checkOtpModel.register_key = '';
          }
          console.log(JSON.stringify(this.checkOtpModel))
          this.http.post(this.funct.ipaddress + 'user/checkOTP', this.checkOtpModel, { headers: headers })
            .pipe(
              catchError(this.handleError.bind(this))
            )
            .subscribe(
              result => {

                this.dto.Response = result;
                this.OtpSms = this.dto.Response;

                // console.log(JSON.stringify(this.OtpSms))

                if (this.OtpSms != null) {
                  if (this.OtpSms.status == true) {

                    if (this.formPage == "forgetPassword") {
                      this.storage.clear('localOtpSms');
                      this.router.navigate(['/resetPassword'], { state: { otp_guid: this.OtpSms.guid }, replaceUrl: true });

                    } else {
                      this.router.navigate(['/account/registration'], { replaceUrl: true });
                    }

                    return true;

                  } else {
                    this.toastr.error("Tip", 'OTP is not correct', {
                      timeOut: 3000,
                      positionClass: 'toast-top-center',
                    });
                    return false;
                  }
                }
              }
            );

        }

      }

    }
  }


  handleError(error: HttpErrorResponse) {

    //console.log(JSON.stringify(error))
    if (error.status == 0) {
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }

    if (error.status == 409) {
      this.toastr.error(this.translateService.instant('record_alerady_exit'), "Please contact admin!", {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }

    if (error.status == 423) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 406) {
      // phone number is taken
      this.toastr.error("Tip", this.translateService.instant('invalidotp'), {
        timeOut: 3000,
        positionClass: 'toast-bottom',
      });

      return false;
    } else if (error.status == 400) {
      // phone number is taken
      this.toastr.error("Tip", this.translateService.instant('invalidotp'), {
        timeOut: 3000,
        positionClass: 'toast-bottom-right',
      });

      return false;
    } 
    // else {
    //   this.toastr.error("Bad request.", 'OTP is not correct', {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-center',
    //   });
    // }
    else{
      this.toastr.error("", error.error.message, {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });      
      return;
    }

    return throwError(error);
  }
  ResendOtp(url) {

    let headers = new HttpHeaders();

    this.http.get(this.funct.ipaddress + url + this.phoneNumber, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          //console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response));     
          this.storage.store('localOtpSms', this.dto.Response);

          if (this.dto.Response.statusCode == 200) {
            if (this.dto.Response.body.split('').trim() == "Not valid OTP code") {
              this.toastr.error("Bad request.", 'OTP is not correct', {
                timeOut: 3000,
                positionClass: 'toast-top-center',
              });

              return null;
            }
            if (this.dto.Response.body.split('').trim() == "Try Again") {
              this.toastr.error("Bad request.", this.dto.Response.body.toString(), {
                timeOut: 3000,
                positionClass: 'toast-top-center',
              });
              return null;
            }


          }

        }
      );
  }
  getOtp() {
    this.time = 120;
    this.startTimer();
    if (this.formPage == "forgetPassword") {
      this.ResendOtp("user/getForgotPassowrdOTP?phoneNo=");
      return
    }
    if (this.actionType == "insertAccount") {
      this.ResendWithdrawOtp();
      return;

    }
    else {
      this.ResendOtp("user/getRegisterOTP?phoneNo=");
      return
    }


  }
  ResendWithdrawOtp() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    //this.storage.retrieve('localInsertBankBankAccount'); //store for next otp page
    this.http.get(this.funct.ipaddress + 'transaction/getWithdrawOTP', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;
          this.storage.store('localInsertAccountOtpSms', this.dto.Response);
        }
      );

  }
  getNewOtp() {
    this.time = 120;
    this.startTimer();
    let headers = new HttpHeaders();

    this.http.get(this.funct.ipaddress + 'user/getRegisterDeviceOTP?phoneNo=' + this.phoneNumber, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;
          this.storage.store('localNewDeviceOtpSms', this.dto.Response);
          if (this.dto.Response.statusCode == 200) {
            if (this.dto.Response.body.split('').trim() == "Not valid OTP code") {
              this.toastr.error("Bad request.", 'OTP is not correct', {
                timeOut: 3000,
                positionClass: 'toast-top-center',
              });

              return null;
            }
            if (this.dto.Response.body.split('').trim() == "Try Again") {
              this.toastr.error("Bad request.", this.dto.Response.body.toString(), {
                timeOut: 3000,
                positionClass: 'toast-top-center',
              });
              return null;
            }


          }

        }
      );
    return;
  }
  
  getNewDeviceOtp() {
   
    let headers = new HttpHeaders();
    this.updateDeviceId.phone_no = this.storage.retrieve('localLoginModel').phone_no;
    this.updateDeviceId.ipAddress = this.storage.retrieve('localLoginModel').ipAddress;
    this.updateDeviceId.guid = this.storage.retrieve('localNewDeviceOtpSms').guid;
    this.updateDeviceId.request_id = this.storage.retrieve('localNewDeviceOtpSms').request_id;
    this.updateDeviceId.code = this.otpcode;
    this.updateDeviceId.deviceId = this.storage.retrieve('localLoginModel').deviceId;

    this.http.post(this.funct.ipaddress + 'user/updateDeviceId', this.updateDeviceId, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;         
          if (this.dto.Response.status == "Success") {           
            this.storage.store('localErrorCode', 304);
            this.router.navigate(['/account/login'], { state: { errorCode: 304 }, replaceUrl: true });
            this.storage.clear('localNewDeviceOtpSms');
            return true;
          }
          else {
            this.toastr.error("Tip", this.dto.Response.message.toString(), {
              timeOut: 3000,
              positionClass: 'toast-top-center',
            });

            return false;
          }
          
        }
      );

  }

  goToServicePhone() {
    this.isOtpService = true;

  }

  showOptPage(e: boolean) {
    this.isOtpService = e;
  }
}
