import { CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { RecaptchaModule } from "ng-recaptcha";
import {
  NotRefreshAppbarComponent,
  PageHeaderComponent,
  AppNavigationBarComponent,
  AppbarComponent,
  HeaderbarComponent,
  LanguagePageComponent,
  LanguageTextPageComponent,
  AdsSliderComponent, AccountLoginComponent, PageHeaderDetailComponent, OtpServicePhoneComponent,
  SharedTableComponent,
  QrReaderComponent,
  
  
} from './basic-components/index';

// modules
import { IonicModule } from '@ionic/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxScannerQrcodeModule } from 'ngx-scanner-qrcode';
import { NgxSpinnerModule } from 'ngx-spinner';
// pipes
import { TimeAgoExtendsPipe } from "./pipes/time-ago.pipe";
import { SafeHtmlPipe } from "./pipes/safe-Html.pipe";
import { RouterModule } from '@angular/router';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { ContactBoxComponent } from './basic-components/contact-box/contact-box.component';
import { CustomdateformatPipe,TwodCloseTimeFormatPipe } from "./pipes/customdateformat.pipe"
import { MaterialModule } from '@baseUrl/src/app/component/agents/agents-material.module';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxEmojiPickerModule }  from  'ngx-emoji-picker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const com = [
  NotRefreshAppbarComponent,
  PageHeaderComponent,
  AppNavigationBarComponent,
  AppbarComponent,
  HeaderbarComponent,
  LanguagePageComponent,
  LanguageTextPageComponent,
  AdsSliderComponent, AccountLoginComponent,
  PageHeaderDetailComponent,
  OtpServicePhoneComponent,
  ContactBoxComponent,
  SharedTableComponent,
  QrReaderComponent,
  
];

const pipes = [
  TimeAgoExtendsPipe,
  SafeUrlPipe,
  CustomdateformatPipe,
  TwodCloseTimeFormatPipe,
  SafeHtmlPipe,
]
@NgModule({
  declarations: [
    com,
    pipes,
    
  ],
  imports: [
    CommonModule,
    IonicModule,
    TranslateModule,
    BsDropdownModule,
    CarouselModule,
    NgxSpinnerModule,
    RouterModule,
    MaterialModule,
    NgxScannerQrcodeModule,
    QRCodeModule,
    FormsModule,
    ReactiveFormsModule,
    RecaptchaModule,
    NgxEmojiPickerModule,   
    
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  exports: [
    com,
    pipes,
    QRCodeModule,
    RecaptchaModule,
    NgxEmojiPickerModule,   
  ],
  providers: [
    pipes,
  ]
})
export class SharedModule { }
