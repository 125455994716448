import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Back4appService {

  keyApplicationId: any;
  keyClientKey: any;
  jsKey: any;
  keyParseServerUrl: any;
  keyLiveQueryUrl: any;
  constructor() { 
  this.keyApplicationId= '4M259VpbdjqPJ46U7EkCH0R8Qt4awj9sAci56ony';
  this.keyClientKey= 'W8zrSSVmNwhRRjYhHINgiNeeWsnP03q7SJBgpVJd';
  this.jsKey='MyWd0ieW0bK5YQEbdzvhGdKh9JVJc5DBmXlEh04j';
  this.keyParseServerUrl = 'https://parseapi.back4app.com';
  this.keyLiveQueryUrl = 'https://happy7788.b4a.io';
  }
}
