
<app-not-refresh-appbar  >{{'helloword' | translate}}</app-not-refresh-appbar>

<div id="appCapsule">
   
<!-- <iframe width="100%" height="700" [src]="urlSafe" allowfullscreen></iframe> -->
   <div *ngIf="game != 'gameList'">
        <div class="container">
            <div class="row pt-3" style="justify-content: center;">
                <img class="bradius" [src]="LaunchGameList.iconurl" alt="gameImage" >          
            </div>
            <div class="row" style="justify-content: center;">         
                <h4 style="color: #2253A2;">{{ changeLanguage(LaunchGameList)}}</h4>
            </div>      
        </div>

        <div class="container" style="background: #ddd; border-radius: 10px; padding: 10px;" >
            <div class="row" >
                       <div class="col-8 d-flex">
                        <img src="assets/img/game_kyat.png" class="game-win-lose-img" alt="" width="20px" height="20px">&nbsp;<h5 class="game-amount" style="font-weight: 600;">{{ 'before-game-balance' |translate}}({{ 'ks' |translate}})</h5>
                       </div>
                       <div class="col-4" *ngIf="!loadingGameBalance"> <h4 class="game-amount">{{ GamePlayResult.balanceBefore }}</h4></div>
                       <div style="position: relative; justify-content: center; background-color: red;" class="mt-2" *ngIf="loadingGameBalance" >
                        <ngx-spinner size = "small" color = "rgb(6,56,107)" type = "ball-spin-clockwise" [fullScreen] = "false"></ngx-spinner>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-8 d-flex">
                        <img src="assets/img/game_wallet.png" alt="" width="20px" height="20px">&nbsp;<h5 class="game-amount" style="font-weight: 600;">{{ 'leave-post-game-balance' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
                       </div>
                       <div class="col-4" *ngIf="!loadingGameBalance"> <h4 class="game-amount">{{ GamePlayResult.postBalance }}</h4></div>
                       <div style="position: relative; justify-content: center; background-color: red;" class="mt-2" *ngIf="loadingGameBalance" >
                        <ngx-spinner size = "small" color = "rgb(6,56,107)" type = "ball-spin-clockwise" [fullScreen] = "false"></ngx-spinner>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-8 d-flex">
                        <img src="assets/img/game_kyat.png" alt="" width="20px" height="20px">&nbsp;
                           <h5 style="font-weight: 600;" class="game-amount" *ngIf="(GamePlayResult.balanceBefore - GamePlayResult.postBalance) &lt; 0">{{ 'game-win' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
                           <h5 style="font-weight: 600;" class="game-amount" *ngIf="(GamePlayResult.balanceBefore - GamePlayResult.postBalance) &gt; 0">{{ 'game-lose' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
                           <h5 style="font-weight: 600;" class="game-amount" *ngIf="(GamePlayResult.balanceBefore - GamePlayResult.postBalance) == 0">{{ 'game-even' |translate}}&nbsp;({{ 'ks' |translate}})</h5>
                       </div>
                       <div class="col-4" *ngIf="!loadingGameBalance"><span class="game-amount" *ngIf="(GamePlayResult.balanceBefore - GamePlayResult.postBalance) &gt; 0" >(-)</span><span class="game-amount" *ngIf="(GamePlayResult.balanceBefore - GamePlayResult.postBalance) &lt; 0"> (+)</span> <span class="game-amount"> {{changeNumber(GamePlayResult.balanceBefore, GamePlayResult.postBalance) }}</span></div>
                       <div style="position: relative; justify-content: center; background-color: red;" class="mt-2" *ngIf="loadingGameBalance" >
                        <ngx-spinner size = "small" color = "rgb(6,56,107)" type = "ball-spin-clockwise" [fullScreen] = "false"></ngx-spinner>
                       </div>
                   </div>
           
       </div>     
        <!-- <div class="row" style="justify-content: center;">
            <button type="button" class="button"(click)="goBack()" style="background-color: #2253A2; color: #fff;width: 80%;">{{'tran_waiting_btn' | translate}}</button>
        </div> -->
    </div>

    
</div>
<ngx-spinner size = "small" color = "rgb(6,56,107)" type = "ball-spin-clockwise" [fullScreen] = "true" *ngIf="game == 'gameList'"></ngx-spinner>
