import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

// library
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

// rxjs
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';

// services
import { DtoService, FunctService, UtilService } from "@services/index";


@Component({
  selector: 'app-profile-detail-page',
  templateUrl: './profile-detail-page.component.html',
  styleUrls: ['./profile-detail-page.component.scss']
})
export class ProfileDetailPageComponent implements OnInit {

  active: string;
  activeLang: any;
  supportLanguages = ['en', 'my', 'th', 'zh'];
  userModel: any;
  token: any;
  imagePath: any;
  imgURL: any;
  message: string;
  phoneNumber: any;
  parentLink: any;

  constructor(private translateService: TranslateService, private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService,
    private router: Router, private storage: LocalStorageService, private funct: FunctService, private location: Location,) {

    this.translateService.addLangs(this.supportLanguages);

    if (this.storage.retrieve('localLanguage') == null || this.storage.retrieve('localLanguage') == '') {
      this.storage.store('localLanguage', 'en');
      this.activeLang = this.storage.store('localLanguage', 'en');

    }
    else {
      this.translateService.setDefaultLang(this.storage.retrieve('localLanguage'));
      this.activeLang = this.storage.retrieve('localLanguage');
    }

    
  }

  ngOnInit(): void {
    this.userModel = {
      name: '',
      phone_no: '',
      imageUrl: '',
      image64BaseData: ''
    }
    this.getUserProfile();

  }
  handleError(error: HttpErrorResponse) {
    if (error.status == 0) {
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }

    if (error.status == 423) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  getUserProfile() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'user/UserProfile', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.userModel = this.dto.Response;
          // this.getPhoneNumber(this.userModel.phone_no);

        });
    this.spinner.hide();
  }

  preview(files) {
    if (files.length === 0)
      return;
    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = "Only images are supported.";
      return;
    }
    var reader = new FileReader();
    this.imagePath = files;
    console.log("Image path is : " + this.imagePath)
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    }
  }

  changeProfile() {
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    if (this.userModel.name.length == 0 || this.userModel.name == null || this.userModel.name == undefined) {
      this.toastr.error(this.translateService.instant("editByUserName"), 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
      return;
    }
    if (this.imgURL != undefined) {
      if (this.imgURL.includes('data:image/jpeg;base64,'))
        this.userModel.image64BaseData = this.imgURL.replace("data:image/jpeg;base64,", "");
      if (this.imgURL.includes('data:image/png;base64,'))
        this.userModel.image64BaseData = this.imgURL.replace("data:image/png;base64,", "");
      if (this.imgURL.includes('data:image/gif;base64,'))
        this.userModel.image64BaseData = this.imgURL.replace("data:image/gif;base64,", "");
    }

    this.http.post(this.funct.ipaddress + 'user/editByUser', this.userModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;
          if (this.dto.Response.status == 'Success') {
            this.router.navigate(["/home"]);
          }
        }
      );
  }

  selectLang(lang: string) {
    this.translateService.use(lang);
    this.storage.store('localLanguage', lang);
    this.active = 'active';

  }
  // getPhoneNumber(phone_no: any){
  //   console.log(this.userModel.phone_no)
  //   if(this.userModel.phone_no !=null){    
  //     this.phoneNumber ="*******" +
  //       this.userModel.phone_no.substring(
  //         this.userModel.phone_no.length - 4,this.userModel.phone_no.length).toString();

  //   } 
  //   //this.phoneNumber="*****3456"
  //   console.log(this.phoneNumber)
  // }
  enter(event) {
    event.target.blur();
  }
  refreshPage() {

  }


}
