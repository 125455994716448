import { Component, OnInit,TemplateRef,ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse  } from '@angular/common/http';

// rxjs
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

// library
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BsModalRef, BsModalService,ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogService } from 'ngx-modal-dialog';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { NgxSpinnerService } from "ngx-spinner";

// components
import { GameMaintanceComponent } from '../game-maintance/game-maintance.component';
import { GamewinloseComponent } from '../gamewinlose/gamewinlose.component';

// services
import { DtoService, FunctService } from "@services/index";

declare var $:any;
@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {
  countryCodeObj : any;
  providerId : any;
  gameList: any;
  gameItems=[];

  adsList : any;
  marqueeText : any;
  iplookupKey : any;
  qmBlackList  = [];
  countryCode : '';
  qmModel : any;
  qmListModel : any;
  localBlackList : [];
  gameAlertObject : any;
  isBlackListCountry : boolean;
  modalId : any;
  gameCode : any;
  providerCode : any;
  launchDameModel: any;
  launchGameResModel : any;
  launchGameModel : any;
  gameType : any;
  token: any;
  topupBalanceModalId : any;
  userProfileBalance : any;
  gameUserBalance : any;
  gameProviderList : any;
  providerName : any;
  bsModalRef: BsModalRef;
  transferAlert: BsModalRef;
  playFreeOrPlay: BsModalRef;
  maintenanceAlert : BsModalRef;
  @ViewChild('maintenanceAlert', {static: false}) 

  templatemaintenanceAlert :  TemplateRef<any>;
  config = {
    animated: true

  };
  isAlert : boolean;
  customOptions: OwlOptions = {    
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    items:1,
   // animateOut: 'fadeOut',    
    autoplay: true,
    autoplaySpeed: 5000,
    autoHeight: true,
    navText: ['', ''],
    navSpeed: 700,
    responsive: {
      400: {
        items: 1 
      },
    },
  }
  searchKey: any;
  isUserLoggedIn= false;
  blackList: any=false;
  deviceId: any;
  closeGameBalance: any;
  gameName: any;
  parentLink: any;
  gameLink: any;
  constructor(private modalService1: ModalDialogService, private viewRef: ViewContainerRef, private translateService: TranslateService, private modalService: BsModalService, private storage: LocalStorageService, private spinner: NgxSpinnerService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private router: Router,
    private route: ActivatedRoute, private funct: FunctService) { 
     this.blackList=history.state.blackList;
     this.closeGameBalance= history.state.closeGameBalance;
     this.parentLink=history.state.parentLink;
     this.providerId = this.route.snapshot.paramMap.get("providerId");
     this.gameLink="/gameList/"+this.providerId; 
     this.deviceId=this.storage.retrieve('localDeviceId'); 
   // console.log(this.providerId)
    
    var page=sessionStorage.getItem('homePage');
    // if(page =='/home'){
    //   if( this.deviceId !=null){
    //     history.replaceState("/home/mobile", null,"/gameList"+this.providerId); 
    //   }
    //   else{
    //     history.replaceState("/home/mobile", null,"/gameList/"+this.providerId); 
    //   }
      
    // }
    // else{
    //   var gameroot= this.storage.retrieve('gameRootUrl');
    //   //console.log(gameroot)
    //   if(gameroot !=null || gameroot !=undefined){
    //    history.pushState(null, null, '/wallet/mywallet');
    //    history.pushState(null, null, this.gameLink);
    //    this.storage.clear('gameRootUrl');      
    //   }
    //   else{
    //     if( this.deviceId !=null){
    //       history.pushState(null, null, '/home/mobile');
    //       history.pushState(null, null, this.gameLink);
    //     }
    //     else{
    //       history.pushState(null, null, '/home');
    //       history.pushState(null, null, this.gameLink);
    //     }
      
    //   }
     
    // }   
    
    }

  ngOnInit(): void {
    
    if( this.closeGameBalance !=0 &&  this.closeGameBalance !=undefined &&  this.closeGameBalance !=null){
      this.gameWinLoseDialog(this.closeGameBalance);
     }
  
    this.deviceId=this.storage.retrieve('localDeviceId');  
    this.isUserLoggedIn= this.storage.retrieve('isUserLoggedIn');        
    this.storage.store('localproviderId', this.providerId);    
    this.getAdsList();
    this.getMarqueeText();
    this.getGameAlert();
    this.getGameUserBalance();
    this.getUserProfile();
    this.getGameList();
    if(!this.blackList){
      this.getBlackListCountry();
    }
   
    this.qmModel =
    {
      "id": 0,
      "countryName": "",
      "countryName_mm": "",
      "countryName_zh": "",
      "countryName_th": "",
      "countryCode2Digit": "",
      "countryCode3Digit": "",
      "currentipAddress":"" ,
      "currentLocation": "",
      "currentLocation_mm": "" ,
      "currentLocation_th":"" ,
      "currentLocation_zh": "",
      "currentCountryCode": ""
    };
    this.qmListModel ={
      "qmBlackList" : [],
      "currentLocation": "",
      "requestCountryCode": "",
      "requestIpAddress": "",
      "ipLookupKey": ""
    }
  
    this.launchDameModel ={
      "gcode": "string",
      "gpcode": "string",
      "lang": "string"
    }

    this.launchGameModel = {
      "gameId": "string",
      "lang": "string",
      "providerCode": "string",
      "type": "string",
      "providerId": "string"
    }
    this.gameProviderList = this.storage.retrieve('localgameProviderList');
    this.gameName= this.storage.retrieve('localgameList'+this.providerId).display_name;
  }
  loginAccount(){
    if(this.deviceId != null){
      this.router.navigate(['/account/login',this.deviceId]);
      return;
    }
    this.router.navigate(['/account/login'])
  }
 
  goBack(){
    if(this.deviceId != null){
      this.router.navigate(['/home',this.deviceId]);
      return;
    }
    this.router.navigate(['/home'])
  }
  ngAfterViewInit() {
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 0){
      this.toastr.error("", 'check your internet connection', {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        });
    }    
    if(error.status == 423 || error.status== 417)
    {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        });
        this.storage.clear('token');
        this.storage.clear('isUserLoggedIn');
    }
    if(error.status == 400)
    {
       this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 1000,
        positionClass: 'toast-top-right',
        });
    }

    if(error.status == 404)
    {
       this.toastr.error("No game list.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }

    if(error.status == 429)
    {
       this.toastr.error("Please contact us.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }


    search() {
    
      this.gameItems=[];
      if(this.searchKey==null ||this.searchKey.length==0){
        this.gameItems= this.gameList.gsGameList;
        return;
      }
     // console.log( this.searchKey);
      this.gameList.gsGameList.forEach(element => {
        if(element.name.includes( this.searchKey)){
          this.gameItems.push(element);
        }
        else if(element.name_my.includes( this.searchKey)){
          this.gameItems.push(element);
        }
        else if(element.name_th.includes( this.searchKey)){
          this.gameItems.push(element);
        }
        else if(element.name_zh.includes( this.searchKey)){
          this.gameItems.push(element);
        }
      });
   
    } 
  getGameList()
  {
    
    this.gameList= this.storage.retrieve('localgameList'+this.providerId);
   
    if(this.gameList!=null&&this.gameList.gsGameList!=null){
      this.gameItems= this.gameList.gsGameList;
    }else{
      this.gameItems=[];
    }   
   
    //console.log(JSON.stringify(this.gameItems.length))
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.set('providerId',this.providerId);
    this.http.get(this.funct.ipaddress + 'loginGS/GetGsGameList', { params:params, headers: headers })
    .pipe(
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.gameList =  this.dto.Response;
        if(this.gameList.isMaintenance) this.maintanceDialog()
        //console.log(JSON.stringify(this.gameList))
        if(this.gameList!=null&&this.gameList.gsGameList!=null){
          this.gameItems = this.gameList.gsGameList;
          this.providerName = this.gameList.name;
        }else{
          this.gameItems=[];
        }
       
        this.gameName= this.gameList.display_name;
        this.storage.store('localgameList'+this.providerId, this.gameList);
        if(this.gameList.isMaintenance == true)
        {
          $("#DialogIconedInfo").modal("show");
          $("#DialogIconedInfo").prependTo("body");
        }
       // this.spinner.hide();
      }
    );
  }
 
  isMaintainceMessage(){
    // console.log(this.gameAlertObject)
      this.toastr.error("", this.gameAlertObject.description_en, {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });

  }

  getAdsList()
  {
    this.adsList=this.storage.retrieve('localadsList');  
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.set('gameProviderId',this.providerId);
    this.http.get(this.funct.ipaddress + 'ads/GetAdsList', { params:params, headers: headers })
    .pipe(
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.adsList =  this.dto.Response;
        this.storage.store('localadsList', this.adsList);
       
      }
    );
  }

  getMarqueeText()
  {
    
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.set('providerId',this.providerId);
    this.http.get(this.funct.ipaddress + 'marquee/getMarqueeText', { params:params, headers: headers })
    .pipe(
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.marqueeText =  this.dto.Response;
        this.storage.store('localmarqueeText', this.marqueeText);
        //this.spinner.hide();
      }
    );
  }

  getBlackListCountry()
  {

    //this.spinner.show();
    let headers = new HttpHeaders();
    this.http.get('https://youripcountry.com/api/country/getCountryCode', { headers: headers })
    .pipe(
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.qmListModel =  this.dto.Response;
        this.iplookupKey = this.qmListModel.ipLookupKey;
        this.countryCode = this.qmListModel.countrycode;
        // console.log(this.iplookupKey)
        this.storage.store('localcountryBlackList', this.qmListModel.qmBlackList);
        for (var i in this.qmListModel.qmBlackList) {
          if(this.countryCode == this.qmListModel.qmBlackList[i].countryCode2Digit)
          {
            this.isBlackListCountry = true;
            break;
          }
          else
          {
            this.isBlackListCountry = false;
          }
      }
      if(this.isBlackListCountry == true)
      {
        this.router.navigate(['/country-black-list']);
        //window.location.href = "./country-black-list";
      }
       
        //this.spinner.hide();
      }
    );
  }

  // getBlackListCountry()
  // {

  //   //this.spinner.show();
  //   let headers = new HttpHeaders();
  //   this.http.get(this.funct.ipaddress + 'loginGS/GetMyCountryCode', { headers: headers })
  //   .pipe(
  //        catchError(this.handleError.bind(this))
  //     )
  //   .subscribe(
  //     result => {
  //       this.dto.Response = {};
  //       this.dto.Response = result;
  //       this.qmListModel =  this.dto.Response;
  //       this.iplookupKey = this.qmListModel.ipLookupKey;
  //       console.log(this.iplookupKey)
  //       this.storage.store('localcountryBlackList', this.qmListModel.qmBlackList);
  //       this.http.get("https://ipapi.co/json?key="+this.iplookupKey, { headers: headers })
  //       .pipe(
  //         catchError(this.handleError.bind(this))
  //        )
  //       .subscribe(
  //           countryCodeResult => {
  //           this.countryCodeObj = countryCodeResult;
  //           this.countryCode = this.countryCodeObj.country_code;
  //           for (var i in this.qmListModel.qmBlackList) {
  //               if(this.countryCode == this.qmListModel.qmBlackList[i].countryCode2Digit)
  //               {
  //                 this.isBlackListCountry = true;
  //                 break;
  //               }
  //               else
  //               {
  //                 this.isBlackListCountry = false;
  //               }
  //           }
  //           if(this.isBlackListCountry == true)
  //           {
  //             this.router.navigate(['/country-black-list']);
  //             //window.location.href = "./country-black-list";
  //           }
  //         }
  //       );
  //       //this.spinner.hide();
  //     }
  //   );
  // }

  getGameAlert()
  {
    this.gameAlertObject=this.storage.retrieve('localgameAlertText');
    //this.spinner.show();
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.set('providerId',this.providerId);
    this.http.get(this.funct.ipaddress + 'GameAlert/GetByProviderId', { params:params, headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.gameAlertObject =  this.dto.Response;
        this.storage.store('localgameAlertText', this.gameAlertObject);
       // this.spinner.hide();
      }
    );
  }

  showplayFreeOrplay(playFreeOrPlay: TemplateRef<any>, transferAlert:TemplateRef<any>, isdemourl, gameCode, providerCode, gameObj)
  {  

    this.storage.store('localGameIcon',gameObj);
    this.storage.store('localLaunchGameList',gameObj);
    let login= this.storage.retrieve('isUserLoggedIn'); 
    if(!login){
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        });
      return;
    }
    if(!this.isUserLoggedIn){
      return;
    }
    this.spinner.show(); 
    this.gameType = gameObj.type; 
    this.providerCode = gameObj.providercode;
    this.gameCode = gameCode;
    if(isdemourl != null && isdemourl == true)
    {      
      this.spinner.hide();     
      this.playFreeOrPlay = this.modalService.show(playFreeOrPlay,
        {
          class: "game-play-modal modal-sm"
        });   
          
    }
    else
    {
    
      this.gameUserBalance = this.getGameUserBalance();
     
      if(this.gameUserBalance < 1000)
      {
       this.spinner.hide();
       this.transferAlert = this.modalService.show(transferAlert,
        {
          class: "game-play-now-modal modal-sm"
        });     
      }
      else
      { 
        this.spinner.hide();
        this.openGame(null);
      }
    }
  }

  openGame(name){    
    if(name == 'free'){
      this.transferAlert.hide();
    }
    this.launchGameModel.gameId = this.providerCode+"_"+this.gameCode;
    this.launchGameModel.providerCode = this.providerName;          
    this.launchGameModel.lang = this.gameLanguage();
    this.launchGameModel.type = this.gameType;
    this.launchGameModel.providerId=this.providerId;
    let headers = new HttpHeaders();
    this.token = this.storage.retrieve('token');  
    headers = headers.set('Authorization', this.token);    
    //console.log(this.launchGameModel)
    this.http.post(this.funct.ipaddress + 'loginGS/launchGames', this.launchGameModel, {headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.launchGameResModel = this.dto.Response;
        this.spinner.hide();
        this.storage.store('localGameBalanceBefore',this.gameUserBalance);
        this.storage.store('localGamePlayProviderId',this.providerId);
        this.storage.store('localPreviousRoute','gameList') 
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if(isSafari){
          var newWin=window.open(this.launchGameResModel.gameUrl);
          if(!newWin || newWin.closed || typeof newWin.closed == undefined){
            this.router.navigate(['/safari-block'],{state: {parentLink: "/gameList/"+this.providerId},replaceUrl:false});
          }
          else{           
            window.open(this.launchGameResModel.gameUrl);
          }             
        }
        else{
         
          this.router.navigate(['/game-play'],{state: {launchUrl: this.launchGameResModel.gameUrl, providerId: this.providerId}}); 
        }
         
      }
    );
  }
  HidePlayFreeOrPlayAlert(){
    this.playFreeOrPlay.hide();
  }
  getGameUserBalance()
  {
    let params = new HttpParams();
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  
    params = params.set('providerId',this.providerId);
    this.http.get(this.funct.ipaddress + 'loginGS/getBalance', {params: params, headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.isUserLoggedIn= true;
        this.dto.Response = {};
        this.dto.Response = result;
        this.gameUserBalance = this.dto.Response.balance;
       // console.log("Game user blance >> "+this.gameUserBalance)
      }
    );
    return this.gameUserBalance;
  }

  /*Play need to edit*/
  play(transferAlert: TemplateRef<any>)
  {   
    this.playFreeOrPlay.hide();    
    this.spinner.show();    
    let params = new HttpParams();
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  
    params = params.set('providerId',this.providerId);
    this.getUserProfile();
    this.http.get(this.funct.ipaddress + 'loginGS/getBalance', {params: params, headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        if(this.dto.Response.balance < 1000)
        {
         this.spinner.hide();
         this.isAlert = true;         
         this.transferAlert = this.modalService.show(transferAlert,
          {
            class: "game-play-now-modal modal-sm",
            ignoreBackdropClick: true, 
            keyboard: false
          });       
        }
        else
        {       
          this.launchGameModel.gameId = this.providerCode+"_"+this.gameCode;
          this.launchGameModel.providerCode = this.providerName;          
          this.launchGameModel.lang = this.gameLanguage();
          this.launchGameModel.type = this.gameType;
          this.launchGameModel.providerId=this.providerId;
          let headers = new HttpHeaders();
          this.token = this.storage.retrieve('token');  
          headers = headers.set('Authorization', this.token);    
         // console.log(this.launchGameModel)
          this.http.post(this.funct.ipaddress + 'loginGS/launchGames', this.launchGameModel, {headers: headers })
          .pipe
            (
               catchError(this.handleError.bind(this))
            )
          .subscribe(
            result => {
              this.dto.Response = {};
              this.dto.Response = result;
              this.launchGameResModel = this.dto.Response;
              this.spinner.hide();
              this.gameUserBalance = this.getGameUserBalance();              
              // window.open(this.launchGameResModel.gameUrl, '_blank');
              this.storage.store('localGameBalanceBefore',this.gameUserBalance);
              this.storage.store('localGamePlayProviderId',this.providerId);
             
              this.storage.store('localPreviousRoute','gameList')  
              sessionStorage.setItem('providerId',this.providerId);
              // console.log(this.launchGameResModel.gameUrl)
              var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
              if(isSafari){
                var newWin=window.open(this.launchGameResModel.gameUrl);
                if(!newWin || newWin.closed || typeof newWin.closed == undefined){
                  this.router.navigate(['/safari-block'],{state: {parentLink: "/gameList/"+this.providerId},replaceUrl:false});
                }
                else{
                  window.open(this.launchGameResModel.gameUrl);
                }             
              }
              else{
                this.router.navigate(['/game-play'],{state: {launchUrl: this.launchGameResModel.gameUrl, providerId: this.providerId,parentLink: '/gameList/'+this.providerId}});
              }
            
            }
          );
        }
      }
    );
  }
  HideAlert()
  {   
    this.transferAlert.hide();
  }
  playFree()
  {    
    this.playFreeOrPlay.hide();
    this.spinner.show();
    this.launchDameModel.gcode = this.gameCode;
    this.launchDameModel.gpcode = this.providerCode;
    this.launchDameModel.lang = "my_MM";
    let headers = new HttpHeaders();
    let params = new HttpParams();
    params = params.set('providerId',this.providerId);
    this.http.post(this.funct.ipaddress + 'loginGS/launchDGames', this.launchDameModel, {headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.launchGameResModel = this.dto.Response;
        this.spinner.hide();
        window.open(this.launchGameResModel.gameUrl, '_blank'); /*launch demo game*/
      }
    );
  }
  getUserProfile()
  {
    let params = new HttpParams();
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  
    this.http.get(this.funct.ipaddress + 'user/UserProfile', {headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userProfileBalance = this.dto.Response.balance;
      }
    );
  }
  createGSGameMember(){
     //console.log('llll')
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  
    let params = new HttpParams();
    params = params.set('providerId',this.providerId);
    this.http.post(this.funct.ipaddress + 'loginGS/gscreatePlayer', null, {params:params, headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {        
        this.dto.Response = result;
        //console.log(this.dto.Response);
  
      }
    );
  }

  changeLanguage(data: any) {
   // console.log(JSON.stringify(this.gameList))
    let language= this.storage.retrieve('localLanguage');
    if (language == "my") {
      return data.name_my != null ? data.name_my : data.name;
    } else if (language == "th") {
      return data.name_zh != null ? data.name_zh : data.name;
    } else if (language == "zh") {
      return data.name_zh != null ? data.name_zh : data.name;
    } else {
      return data.name;
    }
  }
  gameLanguage(){
    let language= this.storage.retrieve('localLanguage');
    if (language == "my") {
      return language+"_"+"MM";
    } else if (language == "th") {
      return language+"_"+"TH"
    } else if (language == "zh") {
      return language+"_"+"CN"
    } 
    else if (language == "en") {
      return language+"_"+"US"
    } 
    else {
      return language+"_"+"US"
    }
  }

  maintanceDialog() {
    this.modalService1.openDialog(this.viewRef, { 
      data:{
        imageUrl:this.gameAlertObject.imageUrl,
        description:this.gameAlertObject.description_en
      },     
      childComponent: GameMaintanceComponent,
      settings: {
        closeButtonClass: 'close theme-icon-close'
      },
    });
  }

  gameWinLoseDialog(closeGameBalance: any) {
    this.spinner.hide();
    this.storage.store('localCloseGameBalance',closeGameBalance);
    this.modalService1.openDialog(this.viewRef, {      
      childComponent: GamewinloseComponent,
      settings: {  
        modalClass:'modal ngx-modal fade show game-win-lose',
        contentClass:"modal-content modal-lg game-win-lose-alert",
        closeButtonClass: 'close theme-icon-close',        
      }
    });
  }
  goToGameWallet(){
    this.transferAlert.hide();   
   // this.storage.store('returnUrl',"/gameList/"+this.providerId)
    this.router.navigate(['/wallet/game-wallet'], {state: {selectGameAccount: this.providerId}})
  }
  refreshPage(){
    this.spinner.show("refreshLoading");
    this.ngOnInit();
    setTimeout(() =>
    {
      this.spinner.hide("refreshLoading");
    }, 1000);
    
  }
  
}

