import { Component, OnInit} from '@angular/core';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import {Location} from '@angular/common';
import { Router ,ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
import { DtoService,FunctService,UtilService } from "@services/index";

@Component({
  selector: 'app-game-invite-code',
  templateUrl: './game-invite-code.component.html',
  styleUrls: ['./game-invite-code.component.scss']
})
export class GameInviteCodeComponent implements OnInit {

  token : any;
  refModel : any={
    referral_code: ''
  };
  userProfileModel : any;  
  constructor( private translateService: TranslateService,
    private toastr: ToastrService, 
    private spinner: NgxSpinnerService, 
    private dto: DtoService, 
    private http: HttpClient, 
    private util: UtilService, 
    private router: Router, 
    private storage: LocalStorageService, 
    private funct: FunctService,
    private location: Location,
    private route: ActivatedRoute,) {
   
     }

  ngOnInit(): void {
    this.getUserProfile();
    
  }
  handleError(error: HttpErrorResponse){
    if(error.status == 0){
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }
    
    if(error.status == 423)
    {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        this.storage.clear('token');
        this.storage.clear('isUserLoggedIn');
    }
    if(error.status == 400)
    {
       this.toastr.error("", this.translateService.instant('referralInvaild'), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }
    return throwError(error);
  }
  getUserProfile(){    
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);    
    this.http.get(this.funct.ipaddress + 'user/UserProfile', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.userProfileModel= this.dto.Response; 
        
        if(this.userProfileModel.gamereferral_code != null && this.userProfileModel.gamereferral_code != "" )
        {
          this.refModel = {
            referral_code: this.userProfileModel.gamereferral_code
          }
          $(".refCode").prop('disabled', true);
          $(".saveBtn").css("display", "none");
        }
        if(this.userProfileModel.gamereferral_code == null  || this.userProfileModel.gamereferral_code == "") {
          this.refModel = {
            referral_code: ''
          }

          //console.log(this.userProfileModel.createdyear)
        }
      }); 
    this.spinner.hide();
  }

  saveInviteCode()
  {
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token); 
    if(this.refModel.referral_code.length == 0 || this.refModel.referral_code == null || this.refModel.referral_code == undefined)
    {
       this.toastr.error(this.translateService.instant("referralCodeEnter"), 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        return;
    }
  
    this.http.post(this.funct.ipaddress + 'user/updategameReferralcode', this.refModel,{ headers: headers })
     .pipe(
       catchError(this.handleError.bind(this))
      )
     .subscribe(
       result => {
         this.dto.Response = result;
         if(this.dto.Response == true)
         {
           this.router.navigate(["/profile/me-page"],{replaceUrl: true});
         }
       }
     );
  }


  enter(event)
  {
    event.target.blur();
  }
}
