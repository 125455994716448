
 <div class="slider">
  <owl-carousel-o [options]="customOptions">
    <ng-container>
        <ng-template carouselSlide *ngFor="let adsObj of this.adsList">           
            <div class="slide">
                <img  [src]="adsObj.imageUrl" alt="AdsImage">
            </div>
        </ng-template>  
    </ng-container>  
  </owl-carousel-o> 

  <div class="overlay" *ngIf = "this.marqueeText != null">
    <marquee direction="left">{{this.marqueeText.marqueeText}}</marquee>
  </div>
 </div>
 
  