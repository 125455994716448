import axios, { AxiosInstance, AxiosRequestConfig, AxiosError } from 'axios';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FunctService } from './funct.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Message } from '../models/message';
import { BehaviorSubject } from 'rxjs';
interface PromiseCallback {
  resolve: (value?: any) => void;
  reject: (error?: any) => void;
}

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  private axiosInstance: AxiosInstance; 
  private isRefreshing: boolean;
  private failedRequests: Array<PromiseCallback>;
 
  constructor(
    private storage: LocalStorageService, 
    private api: FunctService,
    private router: Router, 
    private toast: ToastrService, ) {
    this.axiosInstance = axios.create();
    this.isRefreshing = false;
    this.failedRequests = [];
    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.axiosInstance.interceptors.request.use(
      async (config: AxiosRequestConfig) => {
        const accessToken= this.storage.retrieve('token');    
        if (accessToken!=null && accessToken!=undefined) {
          config.headers = {
            ...config.headers,
            Authorization: "Bearer " + accessToken
          };
        }else{         
          return Promise.reject(new Error('needlogin'));
        }
        return config;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if(error.message=="needlogin"){         
          this.processFailedRequests(error);
          return Promise.reject(error);
        }       
        const originalRequest = error.config;
        if(error.config.timeout ==0 && error.response?.status == 403 ){
          this.isRefreshing = false;          
          this.storage.clear('token');  
          //this.router.navigate(['/user-block'], { state: {message: error.response.data}, replaceUrl: true });          
          this.processFailedRequests(error);
          return Promise.reject(error);
        } 
        // Retry the request if it failed due to an expired token
        if (error.config.timeout == 0||error.response?.status === 401 && !originalRequest['retryAttempt']) {         
          if (this.isRefreshing) {
            // If token is already being refreshed, add the request to the failedRequests array
            return new Promise((resolve, reject) => {
              this.failedRequests.push({ resolve, reject });
            }).then(() => {
              return this.axiosInstance(originalRequest);
            });
          }
          originalRequest['retryAttempt'] = true;
          this.isRefreshing = true;          
        }
        return Promise.reject(error);
      }
    );
  }

  private processFailedRequests(error: AxiosError | null, newToken?: string) {
    this.failedRequests.forEach((prom) => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(newToken);
      }
    });
  }

  async get(url: string) {
    return this.axiosInstance.get(this.api.ipaddress+url);  
  }
  async getChat(url: string) {
    return this.axiosInstance.get(this.api.chatSignalrApi+url);  
  }
  async postChat(url: string, data: any) {
    return this.axiosInstance.post(this.api.chatSignalrApi+url, data);
  }
  async post(url: string, data: any) {
    return this.axiosInstance.post(this.api.ipaddress+url, data);
  }

}
