<div class="service__info--box" >
    <p style="margin: 0%;">{{ contactNo}}</p>
    <div>
        <a href="tel:{{contactNo}}{{openUrl}}">
            <img class="service-image mr-2" src="assets/img/phonebutton.png" alt="">
        </a>         
        <a [href]="'viber://chat?number='+viberNo+''+openUrl | safeUrl" *ngIf="isIOS==false">
            <img class="service-image" src="assets/img/viberbutton.png" alt="" >
        </a>
        <a [href]="'viber://chat?number='+contactNo+''+openUrl | safeUrl" *ngIf="isIOS==true">
            <img class="service-image" src="assets/img/viberbutton.png" alt="" >
        </a>
    </div>
</div>
