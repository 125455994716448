    <!-- App Header -->
    <div class="appHeader text-light">
        <div class="left">
            <a href="javascript:;" [routerLink] = "['/promotion/list']" class="headerButton goBack">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a> 
        </div>
        <div class="pageTitle">Happy7788</div>
        <div class="right">
        </div>
    </div>
    <!-- * App Header -->