import { Component, OnInit, ViewChild } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse  } from '@angular/common/http';

// rxjs
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

// component
import { AccountLoginComponent } from '@baseUrl/src/app/shared/basic-components/account-login/account-login.component';

// library
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';

// services
import { DtoService, FunctService,CommonService } from "@services/index";


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild(AccountLoginComponent) child:AccountLoginComponent;
  gameProviderList : any;
  localgameProviderList: any;
  notiCount: any;
  deviceId: any;
  threedCloseTime:any;
  deviceIdNew: string;
  fcmToken: string;
  token: any;
  chkVersion: any;
  isUserLoggedIn: boolean=false;
  constructor(  private Location: LocationStrategy, private route: ActivatedRoute,private translateService: TranslateService,private router: Router,private dto: DtoService,private toastr: ToastrService,private spinner: NgxSpinnerService,private http: HttpClient,private storage: LocalStorageService,private funct: FunctService,private common: CommonService,) { 
    

    this.deviceIdNew=this.route.snapshot.paramMap.get("deviceId");    
    //console.log(JSON.stringify(this.deviceId))
    //alert('new'+this.deviceIdNew)
   
    if(this.deviceIdNew !=null){
      this.storage.store("localDeviceId",this.deviceIdNew);
    
    }
    this.deviceId=this.storage.retrieve('localDeviceId');  
    
    //alert(this.deviceId)

    history.pushState(null, null, null);
    history.pushState(null, null, null);    
    this.fcmToken= this.route.snapshot.paramMap.get("fcmToken");
    this.chkVersion=this.route.snapshot.paramMap.get("chkVersion");
    if(this.chkVersion !=null){
      this.storage.store('localChkVersion',this.chkVersion);
    }  
    if(this.fcmToken != null){
      this.storage.store('localFcmtoken',this.fcmToken);
      this.updateFCMtoken(this.fcmToken)
    }
  }
  
  ngOnInit(): void {  
    this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');      
    this.gameProviderList= [];
    this.getGameProviderList();
    this.gameProviderList = this.storage.retrieve("localgameProviderList");
    this.notiCount= this.storage.retrieve("localNotiCount");
  }

  goToNotiList() {
    this.router.navigate(['/dashboard/noti-list'],{replaceUrl: true});
  }
  
  handleError(error: HttpErrorResponse){
  
    if(error.status == 0){
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }
    
    if(error.status == 423)
    {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        this.storage.clear('token');
        this.storage.clear('isUserLoggedIn');
    }
    if(error.status == 400)
    {
       this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }

 getGameProviderList()
  {
   
    let headers = new HttpHeaders();
   
    this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', { headers: headers })
    .pipe(
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {
        this.dto.Response = {};
        this.dto.Response = result;
        this.gameProviderList =  this.dto.Response;
        this.storage.store('localgameProviderList', this.gameProviderList);
        
      }
    );
  }

  twodPage() {
  
    this.router.navigate(['/d-page/twod-page'], { state:{parentLink: "/home"},replaceUrl: true});
  }
  threedPage() {    
   
    this.router.navigate(['/d-page/threed-page'], { state:{parentLink: "/home"},replaceUrl: true});
    
   // console.log(this.threedCloseTime)
   
  }
  needHelpPage() {
    this.router.navigate(['/service/need-help']);
  }

  downloadPage(){
    if(this.deviceId !=null){
      this.router.navigate(['/dashboard/download'], { state:{parentLink: "/home/mobile"},replaceUrl: true});
    }
    else{
      this.router.navigate(['/dashboard/download'], { state:{parentLink: "/home"},replaceUrl: true});
    }
    

  }
  refreshPage(){
    this.spinner.show("refreshLoading");
    this.ngOnInit();
    this.child.getUserProfile();
    setTimeout(() =>
    {
      this.spinner.hide("refreshLoading");
    }, 1000);
    
  }

 
  goToGame(id){
    this.storage.store("localGameProviderId",id);
    this.storage.store('localproviderId', id);  
    // this.storage.store('gameRootUrl',"/home");
    sessionStorage.setItem('homePage',null);
    this.router.navigate(['/gameList/',id], {state: { providerId: id ,parentLink: "/home"}});
  }

  updateFCMtoken(token){
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token); 
    var newToken={
      fcmtoken: token
    } 
    this.http.post(this.funct.ipaddress + 'user/updateFcmtoken', newToken, {headers: headers })
    .pipe
      (
         catchError(this.handleError.bind(this))
      )
    .subscribe(
      result => {        
        this.dto.Response = result;
       // console.log(this.dto.Response);
  
      }
    );

  }
}
