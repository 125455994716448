import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

//library
import { ModalDialogService } from 'ngx-modal-dialog';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

// rxjs
import 'rxjs/add/operator/map';

// service
import { DtoService,FunctService,UtilService } from "@services/index";

@Component({
  selector: 'app-language-page',
  templateUrl: './language-page.component.html',
  styleUrls: ['./language-page.component.css']
})
export class LanguagePageComponent implements OnInit {

  selectedIndex: any;
  // supportLanguages: any;
  lang: any;
  supportLanguages = ['en','my','th','zh'];
  constructor(private modalService: ModalDialogService, private viewRef: ViewContainerRef,private translateService: TranslateService,private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService, 
    private router: Router, private storage: LocalStorageService, private funct: FunctService,private location: Location,) {
    // this.supportLanguages = ['en','my','th','zh'];   
    
   
    // const browserlang = this.translateService.getBrowserLang();
    // this.translateService.use(browserlang);
   
  
  }

  ngOnInit() {
    if(this.storage.retrieve('localLanguageIndex') == null){
      this.selectedIndex = this.storage.store('localLanguageIndex',0);
    }
    else{
      this.selectedIndex=this.storage.retrieve('localLanguageIndex');
    }  
        
    if(this.storage.retrieve('localLanguage') == null || this.storage.retrieve('localLanguage') == '')
    {
      this.storage.store('localLanguage', 'en');
      this.lang=this.storage.store('localLanguage', 'en');
     
    }     
    else{
      this.translateService.setDefaultLang(this.storage.retrieve('localLanguage')); 
      this.lang=this.storage.retrieve('localLanguage');
     
    }   
   
    this. changeLanguage(); 
    this.lang=this.storage.retrieve('localLanguage');    
  }

  changeLanguage(){   
     
    this.lang=this.supportLanguages[this.selectedIndex]; 
  }

  selectedLanguageCountry(lang: string,index: number){ 
   
    console.log(this.selectedIndex)
    this.selectedIndex = index;
    this.translateService.use(lang);
    this.storage.store('localLanguage', lang); 
    this.storage.store('localLanguageIndex', this.selectedIndex);    
    this.changeLanguage();  
  }

}
