<!-- App Header -->
<!-- <appbar>{{'your_change_password' | translate }}</appbar> -->
<app-not-refresh-appbar parentLink="/profile/me-page" [rootLevel]=1>{{'your_change_password' | translate }}
</app-not-refresh-appbar>

<div class="main__wrapper">

    <h4 class="text-center">{{'changePasswordPage' | translate}}</h4>
    <form>
        <div class="container">
            <div class="row passwrod-eye">
                <div class="col-1"></div>

                <div class="col-10">
                    <input (keyup.enter)="enter($event)" [type]="passwordType" class="form-control inputFiled"
                        placeholder="{{ 'oldPassword' | translate}}" [(ngModel)]="password"
                        [ngModelOptions]="{standalone: true}" (keyup)="checkPassword()" id="password">
                    <span class="text-eye">
                        <a [routerLink]="" (click)="showPassword(!showPass)">
                            <ion-icon [attr.name]="showPass ? 'eye' : 'eye-off' "></ion-icon>
                        </a>
                    </span>
                    <p class="passError1 ml-5"></p>
                </div>

                <div class="col-1"></div>
            </div>
            <div class="row mx-4">
                <h5>{{'set_password_dec1' |translate }}</h5>
                <h5>{{'forget_password_dec2' | translate}}</h5>
            </div>

            <div class="row passwrod-eye">
                <div class="col-1"></div>
                <div class="col-10">
                    <input (keyup.enter)="enter($event)" [type]="passwordType" class="form-control inputFiled"
                        placeholder="{{ 'newPasswordHint' | translate}}" [(ngModel)]="newPassword"
                        [ngModelOptions]="{standalone: true}" (keyup)="checkNewPassword()">
                    <span class="text-eye">
                        <a [routerLink]="" (click)="showPassword(!showPass)">
                            <ion-icon [attr.name]="showPass ? 'eye' : 'eye-off' "></ion-icon>
                        </a>
                    </span>
                    <p class="passError2 ml-5"></p>
                </div>
                <div class="col-1"></div>
            </div>
            <br />
            <div class="row passwrod-eye">
                <div class="col-1"></div>

                <div class="col-10">
                    <input (keyup.enter)="enter($event)" [type]="passwordType" class="form-control inputFiled"
                        placeholder="{{ 'regconfirmpasswordHint' | translate}}" [(ngModel)]="confirmPassword"
                        [ngModelOptions]="{standalone: true}" (keyup)="checkConfirmPassword()">
                    <span class="text-eye">
                        <a [routerLink]="" (click)="showPassword(!showPass)">
                            <ion-icon [attr.name]="showPass ? 'eye' : 'eye-off' "></ion-icon>
                        </a>
                    </span>
                    <p class="passError3 ml-5"></p>
                </div>

                <div class="col-1"></div>
            </div>
            <div class="mt-2" style=" position: relative; justify-content: center; background-color: red;" class="mt-2"
                *ngIf="loadingSubmiting">
                <ngx-spinner size="medium" color="grey" name="loadingSubmiting" type="ball-spin-clockwise"
                    [fullScreen]="false"></ngx-spinner>
            </div>
            <button type="button" class="btn login-button" (click)="changePassword()" *ngIf="!loadingSubmiting">{{
                'account_change' | translate}}</button>
        </div>
    </form>

</div>

<!-- <app-navigation-bar></app-navigation-bar> -->