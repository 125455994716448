import { Component, OnInit, ViewChild } from '@angular/core';
import {Location} from '@angular/common';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import 'rxjs/add/operator/map';
import { Router } from '@angular/router';


// services
import { DtoService, UtilService, FunctService } from "@services/index";

// libraries
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-ads-skip-page',
  templateUrl: './ads-skip-page.component.html',
  styleUrls: ['./ads-skip-page.component.css']
})
export class AdsSkipPageComponent implements OnInit {
  time: number = 5;
  counterTimer ;
  interval;
  constructor(private translateService: TranslateService,private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService, 
    private router: Router, private storage: LocalStorageService, 
    private funct: FunctService,private location: Location,) {
    this.startTimer();
   }

  ngOnInit(): void {
  }
  startTimer() {     
    this.interval = setInterval(() => {
      if (this.time === 5) {
        this.time--;
      }
      if (this.time === 0) {
        this.router.navigate(['/home']);
        this.time=0;
       
      } else {
        this.time--;
      }
      this.counterTimer=this.transform(this.time)
    }, 1000);
    return this.counterTimer;
  }
  transform(value: number): string {
      
      const seconds: number = Math.floor(value);        
      return '' + (value);
  }
  goToHome(){
    this.router.navigate(['/home']);
  }

}
