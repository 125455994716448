import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-qr-reader',
  templateUrl: './qr-reader.component.html',
  styleUrls: ['./qr-reader.component.scss']
})
export class QrReaderComponent implements OnInit {

  @Output() onQRValueEmit = new EventEmitter();

  public qrCodeResult2: ScannerQRCodeSelectedFiles[] = [];
  @ViewChild('file2') qrFileElement: any;
  public config: ScannerQRCodeConfig = {
    // fps: 100,
    // isBeep: false,
    // decode: 'macintosh',
    // deviceActive: 1,
    constraints: { 
      audio: false,
      video: {
        width: window.innerWidth
      }
    } 
  };
  
  constructor(private qrcode: NgxScannerQrcodeService, private toastr: ToastrService){}

  ngOnInit(): void {
  }

  public onSelects2(files: any): void {
    console.log(files)
    console.log(this.qrcode)
    this.qrcode.loadFilesToScan(files, this.config).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      console.log(res);
      if (res[0]?.data.length != 0) {
        console.log(res[0].data[0].value)
        console.log(res[0].data[0].value.includes('BTEwMC4w'))
        if (res[0].data[0].value.includes('BTEwMC4w')) {
          this.qrCodeResult2 = res;        
          this.onQRValueEmit.emit({qrValue:res[0].data[0].value,localfile:files[0]});
          // this.qrValue = res[0].data[0].value;
          // console.log(res[0].data[0].value)
          setTimeout(() => {
            (document.getElementById('qr-image') as HTMLImageElement).src = URL.createObjectURL(files[0])
          }, 100);
        } else {
          // this.qrFileElement.nativeElement.value = "";
          this.toastr.error("", 'Your QR Code is something wrong!', {
            timeOut: 3000,
            positionClass: 'toast-top-center',
          });
          return;
        }

      } else {
        // this.qrFileElement.nativeElement.value = "";
        this.toastr.error("", 'Your QR Code is something wrong!', {
          timeOut: 3000,
          positionClass: 'toast-top-center',
        });
        return;
      }
    });
  }

  isShowImage:boolean=false;
  addImage(file){
    console.log(file)
    this.isShowImage=true;
    console.log(document.getElementById('qr-image'))
    setTimeout(() => {
      (document.getElementById('qr-image') as HTMLImageElement).src = URL.createObjectURL(file)
    }, 200);

  }
  

}
