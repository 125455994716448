
import { LocationStrategy, PlatformLocation } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { DtoService } from 'src/app/service/dto.service';
import { FunctService } from 'src/app/service/funct.service';
import { throwError } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-game-win-lose-page',
  templateUrl: './game-win-lose-page.component.html',
  styleUrls: ['./game-win-lose-page.component.scss']
})
export class GameWinLosePageComponent {
  launchUrl: any;
  // name = 'Set iframe source';
  // url: string = "https://m13.ns86.kingmakergames.co/games/dragon-tiger-2/index.html?lang=en-US";
  urlSafe: SafeResourceUrl;
  providerId: any;
  gameBalanceAfter : any;
  token: any;
  userProfileModel: any;
  GamePlayResult: any;
  DoBalanceCloseGame: any;
  LaunchGameList: any;
  loading: any;
  game: any;

  checkWindow = false;
  fbAuthWindow: any;
  parentLink: any;
  loadingGameBalance: boolean;
  constructor(private spinner: NgxSpinnerService,public sanitizer: DomSanitizer,private Location: LocationStrategy,private dto: DtoService, private router: Router,
    private funct: FunctService ,private http: HttpClient,private toastr: ToastrService,private translateService: TranslateService, private storage: LocalStorageService,) {
   this.launchUrl=history.state.launchUrl;
   this.providerId=history.state.providerId;
   this.parentLink=history.state.parentLink;
   //  console.log(this.providerId)
  //  console.log(this.parentLink)
  //  history.pushState(null, null, "/gameList/" + this.providerId);
  //  history.pushState(null, null,'/game-play');

   this.spinner.show();
   if(this.launchUrl == undefined || this.launchUrl == null){ 
    this.storage.clear('localPreviousRoute');
    this.game=this.storage.retrieve('localPreviousRoute');    
    this.getCalculate();
    window.onpopstate = function(event) { 
      sessionStorage.setItem('homePage','/home');
      history.replaceState("/gameList/"+sessionStorage.getItem('providerId'), null,"/game-play");
    };
   }

  }

  async ngOnInit() {      
    //alert(this.launchUrl)
    this.spinner.show();
    this.game=this.storage.retrieve('localPreviousRoute');
    if(this.launchUrl != undefined || this.launchUrl != null){     
      this.fbAuthWindow= window.open(this.launchUrl);     
      this.checkWindow = true;
      setTimeout(() => this.checkAuthWindow(), 500);
      // this.goBack();
   
     }
    //  else{
    //  // alert('mobile close')
    //   this.storage.clear('localPreviousRoute');
    //   this.game=this.storage.retrieve('localPreviousRoute');
    //   this.goBack();
    //   this.spinner.hide();
     
    //  }
    
    this.providerId=this.storage.retrieve('localGamePlayProviderId');    
    this.getUserProfile();
   
    this.LaunchGameList=this.storage.retrieve('localLaunchGameList');
    this.GamePlayResult={
      balanceBefore: 0,
      postBalance: 0,
      providerId: "",
      differentAmount: 0,
      status: "",
      signature: ""
    } 
    
  }

  checkAuthWindow(){
    if(this.checkWindow == true){
          if(this.fbAuthWindow.closed){          
           this.storage.clear('localPreviousRoute');
           this.game=this.storage.retrieve('localPreviousRoute');
           this.goBack();
            
        } 
        else {
          setTimeout(() => this.checkAuthWindow(), 500);
        }     
    }
 }
  changeLanguage(data: any) {
    // console.log(JSON.stringify(this.gameList))
     let language= this.storage.retrieve('localLanguage');
     if (language == "my") {
       return data.name_my != null ? data.name_my : data.name;
     } else if (language == "th") {
       return data.name_zh != null ? data.name_zh : data.name;
     } else if (language == "zh") {
       return data.name_zh != null ? data.name_zh : data.name;
     } else {
       return data.name;
     }
   }
  handleError(error: HttpErrorResponse){
    if(error.status == 0){
      this.toastr.error("", 'check your internet connection', {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        });
    }
    
    if(error.status == 423 || error.status== 417)
    {
      
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        });
        this.storage.clear('token');
        this.storage.clear('isUserLoggedIn');
    }
    if(error.status == 400)
    {
       this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        });
    }

    if(error.status == 404)
    {
       this.toastr.error("No game list.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }

    if(error.status == 429)
    {
       this.toastr.error("Please contact us.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }
    return throwError(error);
    }
  sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
  }
  
  getUserProfile(){    
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);    
    this.http.get(this.funct.ipaddress + 'user/PointUserProfile', { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
    )
    .subscribe(
      result => {
        this.dto.Response = result;
      //  console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response)); 
        this.storage.store('localGameUserProfile',this.dto.Response);
      
      }); 
   
  }
 
  goBack()
  {
    this.loadingGameBalance=true;
    this.spinner.show();
    this.providerId=this.storage.retrieve('localproviderId');
    var gameBalanceBefore=this.storage.retrieve('localGameBalanceBefore');
   
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  
     console.log(gameBalanceBefore)
    this.GamePlayResult.balanceBefore=gameBalanceBefore;
    this.GamePlayResult.postBalance=gameBalanceBefore;
    this.GamePlayResult.providerId=this.providerId;  
      console.log(JSON.stringify(this.GamePlayResult))      
      this.http.post(this.funct.ipaddress + 'loginGS/DoBalanceCloseGame',this.GamePlayResult, { headers: headers })
      .pipe
        (
          catchError(this.handleError.bind(this))
        )
      .subscribe(
        result => {
          this.dto.Response = result;         
          this.DoBalanceCloseGame=result;                       
                 
          console.log("dobalance"+JSON.stringify(this.dto.Response));
          if(this.DoBalanceCloseGame.status == "Success"){ 
            console.log("post"+this.DoBalanceCloseGame.postBalance);            
            this.GamePlayResult.balanceBefore=gameBalanceBefore;
            this.GamePlayResult.postBalance=parseInt(this.DoBalanceCloseGame.postBalance);   
            this.loadingGameBalance=false;
            this.spinner.hide();                                         
          }
        }
      ); 
  }

  getCalculate(){
    this.GamePlayResult={
      balanceBefore: 0,
      postBalance: 0,
      providerId: "",
      differentAmount: 0,
      status: "",
      signature: ""
    } 
    this.loadingGameBalance=true;
    this.spinner.show();
    this.providerId=this.storage.retrieve('localproviderId');
    var gameBalanceBefore=this.storage.retrieve('localGameBalanceBefore');
   
    //console.log(gameBalanceBefore)
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);  

    this.GamePlayResult.balanceBefore= gameBalanceBefore;
    this.GamePlayResult.postBalance=gameBalanceBefore;
    this.GamePlayResult.providerId=this.providerId;  
      console.log(JSON.stringify(this.GamePlayResult))      
      this.http.post(this.funct.ipaddress + 'loginGS/DoBalanceCloseGame',this.GamePlayResult, { headers: headers })
      .pipe
        (
          catchError(this.handleError.bind(this))
        )
      .subscribe(
        result => {
          this.dto.Response = result;         
          this.DoBalanceCloseGame=result;                     
                 
          console.log("dobalance"+JSON.stringify(this.dto.Response));
          if(this.DoBalanceCloseGame.status == "Success"){ 
            console.log("post"+this.DoBalanceCloseGame.postBalance);            
            this.GamePlayResult.balanceBefore=gameBalanceBefore;
            this.GamePlayResult.postBalance=parseInt(this.DoBalanceCloseGame.postBalance);   
            this.loadingGameBalance=false;
            this.spinner.hide();                                         
          }
        }
      );
  }
  

  
  changeNumber(n1,n2){ 
    // console.log(n1)
    // console.log(n2)
    return  Math.abs(n1 - n2); 
   }
}

@Pipe({
  name: 'safeUrl'
})
export class GameSafeUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {}
  transform(url) {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
}
}