import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { formatDate, formatNumber } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';

// rxjs
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

// library
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';

// services
import { DtoService, FunctService } from "@services/index";

@Component({
  selector: 'app-noti-detail-without-id',
  templateUrl: './noti-detail-without-id.component.html',
  styleUrls: ['./noti-detail-without-id.component.scss']
})
export class NotiDetailWithoutIdComponent implements OnInit {

  data: any;
  token: any;

  guid: any;
  isUserLoggedIn: boolean = false;
  userProfileModel: any;
  userlogin: any = false;
  deviceId: any;
  constructor(private router: Router, private translateService: TranslateService, private dto: DtoService, private funct: FunctService, private http: HttpClient, private toastr: ToastrService,
    private storage: LocalStorageService, private sanitizer: DomSanitizer, private route: ActivatedRoute,) {
    this.deviceId = this.storage.retrieve('localDeviceId');
    this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');
    // alert(userlogin)
    // if(userlogin == false){
    //   this.toastr.error("", this.translateService.instant("youNeedLogin"), {
    //     timeOut: 3000,
    //     positionClass: 'toast-top-center',
    //     });
    //     return;
    // }
  }


  ngOnInit(): void {

    if (this.isUserLoggedIn == false) {
      return;
    }
    else {

      this.data = {
        isFirstTopup: ''
      }

      let dataString = this.route.snapshot.paramMap.get("data");
      console.log(dataString)
      console.log(dataString.length)
      if (dataString.length > 50) {
        //console.log(dataString)
        this.data = JSON.parse(dataString);
        console.log(this.data)
        return;
      }
      else {
        this.guid = this.route.snapshot.paramMap.get("data");
        console.log(this.guid)
        this.getAllNoti();
        this.getUserProfile();
      }




    }
  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 0) {
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }

    if (error.status == 423 || error.status == 417) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      // this.storage.clear('token');
      // this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }
    return throwError(error);
  }
  getUserProfile() {
    this.isUserLoggedIn = false;
    this.token = this.storage.retrieve('token');
    // console.log(this.token)
    if (this.token != null) {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.token);
      this.http.get(this.funct.ipaddress + 'user/PointUserProfile', { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            // console.log("this.dto.Response>> " + JSON.stringify(this.dto.Response)); 
            this.userProfileModel = this.dto.Response;
            this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');
            // this.showBalance= this.userProfileModel.balance;  

          });
      // this.spinner.hide();
    }

  }
  getAllNoti() {
    this.token = this.storage.retrieve('token');

    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'notification/GetNotificationList', { headers: headers }).subscribe(
      result => {
        this.dto.Response = result;
        this.dto.Response.forEach(e => {
          if (e.guid == this.guid) {
            e.status == "0";
            this.data = e;
          }
          console.log(JSON.stringify(this.data))
        });
      }

    );

  }
  getFirstTopUpStr() {
    var str = this.translateService.instant('point-detail-des');
    str = str.replaceAll('{{percentage}}', this.data.percentage);
    str = str.replaceAll('{{kyat}}', this.data.pointWallet);
    str = str.replaceAll('{{pointNo}}', this.data.account_no);
    return str;

  }
  getWithdrawNotiStr(state) {
    // console.log("bill" + this.data.transaction_no);
    var no = this.data.account_no
    if (no != null && no != "" && no != undefined) {
      if (no.length > 5) {
        no = no.replaceAll("+959", "09").substr(0, 5) + "*** ***";
      }
    }
    var str = "";
    if (state == "APPROVED") {
      str = this.translateService.instant('withdrawal_success');
    }
    else {
      str = this.translateService.instant('withdrawal_denied');
    }
    str = str.replace('{{kyat}}', this.data.bill);
    str = str.replace('{{tracNo}}', no);
    return str;
  }

  getTopupNotiStr(state) {
    //console.log("bill" + this.data.transaction_no);
    var no = this.data.transaction_no != null && this.data.transaction_no != "" && this.data.transaction_no != undefined ? this.data.transaction_no : this.data.account_no

    var str = "";
    if (state == "APPROVED") {
      str = this.translateService.instant('top_up_success');
    }
    else {
      str = this.translateService.instant('top_up_denied');
    }
    str = str.replace('{{kyat}}', this.data.bill);
    str = str.replace('{{tracNo}}', no);
    return str;
  }
  getBalance() {
    if (this.data.balance != null && this.data.balance != "" && this.data.balance != undefined) {
      return formatNumber(this.data.balance, '1.2-2') + "KS";
    }
    else return "";
  }
  getDateTime(date) {
    var formatter = 'yyyy/MM/dd hh:mm a';
    return formatDate(date, formatter, 'en-US');
  }

  getWinnerDate() {
    var dateStr = "";
    if (this.data.request_date != null && this.data.request_date != "" && this.data.request_date != undefined) {
      dateStr = this.getDateTime(this.data.request_date);
      return dateStr;
    }
    else if (this.data.currentdate != null && this.data.currentdate != "" && this.data.currentdate != undefined) {
      dateStr = this.getDateTime(this.data.currentdate);
      return dateStr;
    }
    else {
      return "";
    }

  }
  loginAccount() {
    if (this.deviceId != null) {
      //alert(this.deviceId)
      this.router.navigate(['/account/login', this.deviceId], { state: { parentLink: "/home/mobile" }, replaceUrl: true });
      return;
    }
    this.router.navigate(['/account/login'], { state: { parentLink: "/home" }, replaceUrl: true })
  }

  changeResult1(detail) {
    var v1 = detail.substring(0, detail.length - 2);
    return v1;
  }
  changeResult2(detail) {
    var v2 = detail.substring(detail.length - 2, detail.length);
    return v2;
  }
}
