import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

// rxjs
import 'rxjs/add/operator/map';

// library
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

// service
import { DtoService, FunctService, UtilService } from "@services/index";

@Component({
  selector: 'logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  deviceId: any;
  constructor(private toastr: ToastrService, private spinner: NgxSpinnerService,
    private dto: DtoService, private http: HttpClient, private util: UtilService,
    private router: Router, private storage: LocalStorageService, private funct: FunctService) {

  }

  ngOnInit(): void {
    this.deviceId = this.storage.retrieve('localDeviceId');
  }

  logOut() {         //this.storage.clear();
    this.util.isLogged = false;
    this.dto.token = "";
    this.storage.store('agentModel','');
    this.storage.store('token', this.dto.token);
    this.storage.store('isUserLoggedIn', this.util.isLogged);
    if (this.deviceId != null) {
      this.router.navigate(['/account/login', this.deviceId], { replaceUrl: true });
      return;
    }
    this.router.navigate(['/account/login'], { replaceUrl: true })
 
  }

}
