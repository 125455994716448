import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalvariableService {
  public phonenumber:any;

  constructor() { }

  setPhoneNumber(ph:any){
    this.phonenumber=ph;
  }

  getPhoneNumber() : string{
    return this.phonenumber;
  }
}
