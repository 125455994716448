<div class="appHeader text-light">
  <div class="left">
    <a (click)="back()" class="headerButton">
      <ion-icon name="arrow-back"></ion-icon>
      <!-- <i class="fa fa-arrow-left" aria-hidden="true"></i> -->
    </a>
  </div>
  <div class="pageTitle">
    <ng-content></ng-content>
  </div>
  <div class="right">
    <a class="headerButton" *ngIf="!customright">
      <ion-icon name="refresh-outline" (click)="refreshPage()"></ion-icon>
    </a>
    <a (click)="withdrawAdd()" style="color: #fff;" *ngIf="customright">
      <!-- <ion-icon name="notifications-outline"></ion-icon> -->
      <ion-icon name="add-circle-outline"></ion-icon>
  </a>
  </div>
</div>