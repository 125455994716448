import { Component, OnDestroy, OnInit } from '@angular/core';
import { SignalrService } from '@services/index';
import { LocalStorageService } from 'ngx-webstorage';
@Component({
  selector: 'app-stream-live',
  templateUrl: './stream-live.component.html',
  styleUrls: ['./stream-live.component.scss']
})
export class StreamLiveComponent implements OnInit, OnDestroy {
  datas: any;
  constructor(public signalRService: SignalrService, private storage: LocalStorageService) {
  }
  ngOnInit() {
    this.signalRService.startConnection();
    this.signalRService.addTransferChartDataListener();
  }

  ngOnDestroy(): void {
    this.storage.store('sectionResultList', this.signalRService.data);
  }


}