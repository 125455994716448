import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-language-text-page',
  templateUrl: './language-text-page.component.html',
  styleUrls: ['./language-text-page.component.css']
})
export class LanguageTextPageComponent implements OnInit {
  supportLanguages: any;
  constructor(private translateService: TranslateService) {
    this.supportLanguages = ['en','my','th','zh'];
    this.translateService.addLangs(this.supportLanguages);
    this.translateService.setDefaultLang('en');
    const browserlang = this.translateService.getBrowserLang();
    this.translateService.use(browserlang);
  }

  ngOnInit(): void {
  }
   selectLang(lang: string){
    this.translateService.use(lang);   
  }


}
