import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

// rxjs
import 'rxjs/add/operator/map';
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

// libraries
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';

// services
import { DtoService, UtilService, FunctService } from "@services/index";

@Component({
  selector: 'app-account-login',
  templateUrl: './account-login.component.html',
  styleUrls: ['./account-login.component.css']
})
export class AccountLoginComponent implements OnInit {
  token: any;
  userProfileModel: any;
  isUserLoggedIn;
  showAmount = true;
  showBalance: any = false;
  deviceId: any;
  notiCount: any;
  constructor(private translateService: TranslateService, private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService,
    private router: Router, private storage: LocalStorageService, private funct: FunctService, private location: Location,) {
    this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');
    this.deviceId = this.storage.retrieve('localDeviceId');

  }
  ngOnInit(): void {

    var localnoti = this.storage.retrieve('localNewNotiCount');
    //alert(localnoti)
    if (localnoti != null && this.isUserLoggedIn) {
      this.notiCount = this.storage.retrieve('localNewNotiCount');
    } else {
      this.notiCount = 0;
    }
    this.getAllNoti();
    this.showBalance = '';
    this.userProfileModel = {
      balance: ''
    }

    this.showAmount = this.storage.retrieve('showAmount');

    this.showAmount = this.showAmount == null ? false : this.showAmount;
    this.userProfileModel = '';
    this.token = this.storage.retrieve('token');
    // console.log(this.token)      
    if (this.token == null) {
      this.storage.store('token', null);
      this.userProfileModel = null;
      this.storage.store('isUserLoggedIn', false);
    }
    this.isUserLoggedIn = this.storage.retrieve('isUserLoggedIn');
    if (this.storage.retrieve('localShowBalance') == null) {
      this.storage.store('localShowBalance', true);
    }
    this.getUserProfile();
    this.showAmount = this.storage.retrieve('localShowBalance');
    // console.log(this.showAmount)

    if (!this.showAmount) {
      this.showBalance = "*****";
      return;
    }

    //this.showBalance= "0";

  }
  loginAccount() {
    if (this.deviceId != null) {
      //alert(this.deviceId)
      this.router.navigate(['/account/login', this.deviceId], { state: { parentLink: "/home/mobile" }, replaceUrl: true });
      return;
    }
    this.router.navigate(['/account/login'], { state: { parentLink: "/home" }, replaceUrl: true })
  }
  handleError(error: HttpErrorResponse) {
    if (error.status == 0) {
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
    }

    if (error.status == 423) {

      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  getUserProfile() {
    this.token = this.storage.retrieve('token');
    // console.log(this.token)
    if (this.token != null) {
      let headers = new HttpHeaders();
      headers = headers.set('Authorization', this.token);
      this.http.get(this.funct.ipaddress + 'user/UserProfile', { headers: headers })
        .pipe(
          catchError(this.handleError.bind(this))
        )
        .subscribe(
          result => {
            this.dto.Response = {};
            this.dto.Response = result;
            this.storage.store('myId', btoa(result.id));
            this.userProfileModel = this.dto.Response;
            if (this.showAmount) {
              this.showBalance = this.userProfileModel.balance;
            }

          });
      this.spinner.hide();
    }

  }

  showPassword(show: boolean) {
    this.storage.store('showAmount', show);
    this.showAmount = show;
    this.storage.store('localShowBalance', this.showAmount);
    if (this.showAmount) {
      this.showBalance = this.userProfileModel.balance;

    }



  }
  openWalletPage() {
    this.router.navigate(['/wallet/mywallet', '1'], { state: { parentLink: "/home" }, replaceUrl: true });
  }
  goToNotiList() {
    this.router.navigate(['/dashboard/noti-list'], { state: { parentLink: "/home" }, replaceUrl: true });
  }

  changeName() {
    // console.log(name.length)
    var name = this.userProfileModel.name;
    if (name != null && name.length > 30) {
      name = name.substring(0, 20) + " ...";
      return name;
    }
    else {
      return name;
    }
  }

  checkPhoneNumber() {

    let ph = this.userProfileModel.phone_no;
    return new Array(ph.length - 3).join("*") + ph.substring(ph.length - 3);
  }
  goToProfileDetail() {
    if (this.deviceId != null) {
      this.router.navigate(['/profile-detail'], { state: { parentLink: '/home/mobile' }, replaceUrl: true });
    }
    else {
      this.router.navigate(['/profile-detail'], { state: { parentLink: '/home' }, replaceUrl: true });
    }
    // [routerLink]="['/profile-detail']" replaceUrl="true" [state]="{parentLink: '/home'}"
  }

  getAllNoti() {
    var userlogin = this.storage.retrieve('isUserLoggedIn');
    if (!userlogin) {
      this.notiCount = 0;
      return;
    }
    // var localnoti=this.storage.retrieve('localNotiList');
    // if(localnoti !=null){
    //   this.notiList=localnoti;
    // } 
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'notification/GetNotificationList', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = result;
          var newcount = 0;
          this.dto.Response.forEach(e => {
            if (e.status == 0) {
              newcount++;
            }

          });
          this.notiCount = newcount;
        }

      );
    // console.log("Noti List: " + this.notiList);
  }

}
