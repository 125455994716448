import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { TranslateService } from '@ngx-translate/core';
import { FunctService } from '@baseUrl/src/app/service';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { forkJoin, throwError } from 'rxjs';

@Component({
  selector: 'app-stream-page',
  templateUrl: './stream-page.component.html',
  styleUrls: ['./stream-page.component.scss']
})
export class StreamPageComponent implements OnInit,OnChanges {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,    
    animateOut: 'fadeOut' ,// and this
    animateIn: 'flipInX',
    navSpeed: 100,
    autoHeight: true,
    navText: ['', ''],
    responsive: {
      400: {
        items: 1
      },
      // 0: {
      //   items: 1
      // },
      // 400: {
      //   items: 2
      // },
      740: {
        items: 1
      },
      // 940: {
      //   items: 4
      // }
    },
    // nav: true
  }

  public clock: any;
  public todaydate: any;
  public streamDatas:any;
  @Input() data: any = [];
  sectionList: any = [];
  constructor(private http: HttpClient, 
    private funct: FunctService, 
    private storage: LocalStorageService,
    private toastr: ToastrService, 
    private translateService: TranslateService,
   ) {
    document.body.className = "full-screen-size";
    this.todaydate = new Date();
    setInterval(() => {
      this.clock = moment().format('h:mm:ss a');
    }, 1000) // Updates the time every second.
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.sectionList = this.data ? JSON.parse(this.data?.data) : [];
    if (this.data) {      
      document.getElementById('result').innerHTML = this.data?.result;
      let showAnimation = false;
      for (let i = 0; i < this.sectionList.length; i++) {
        var item = this.sectionList[i];
        var from = new Date(item.fromDateTime);
        var to = new Date(item.toDateTime);
        var now = new Date();                        
        if ((from.getTime() < now.getTime() && to.getTime() > now.getTime() && (item.section != "12:01 PM" && !item.isDone)) ||
            (from.getTime() < now.getTime() && to.getTime() > now.getTime() && (item.section == "12:01 PM")) ||
            (from.getTime() == now.getTime() && !item.isDone) ||
            (to.getTime() == now.getTime() && (item.section != "12:01 PM" && !item.isDone))
        ) {
            var set0 = '--';
            var set1 = '';
            if (item.set != "--" && item.set != null && item.set != '') {
                set0 = item.set.substr(0, item.set.length - 1);
                set1 = item.set.substr(item.set.length - 1, item.set.length);
            }
            var val0 = '--';
            var val1 = '';
            var val2 = '';            
            if (item.value != "--" && item.value != null && item.value != '') {
                val0 = item.value.substr(0, item.value.length - 4);
                val1 = item.value.substr(item.value.length - 4, 1);
                val2 = item.value.substr(item.value.length - 3, item.value.length);
            }
            document.getElementById('setId').innerHTML = `<span>${set0}</span ><span style='color: #eabe3b;'>${set1}</span>`;
            document.getElementById('valueId').innerHTML = `<span>${val0}</span><span style='color: #eabe3b;'>${val1}</span><span>${val2}</span>`;
            if(item.isDone) {
              showAnimation = false;
            }
            else{
              showAnimation = true;
            }
             //showAnimation = true;
            break;
        }   
        else{
          showAnimation = false;
        }    
    }
    if (showAnimation == false) { 
        document.getElementById('result').classList.remove('resultStyleAnimate');
        document.getElementById('result').className = 'resultStyle';
    }
    else {        
        document.getElementById('result').className = 'resultStyleAnimate';
    }      
    } else {     
     
    }

  }
  async ngOnInit(): Promise<void> {
    
    const tempSectionResult = this.storage.retrieve('sectionResultList');
    if (tempSectionResult != null && tempSectionResult != "") {
      
      this.data = tempSectionResult;
    }
   
    this.getData();
  }
  

  getData() {
  //  console.log('reach there')
    let headers = new HttpHeaders();
    forkJoin(
      {
        tableData: this.http.get(this.funct.ipaddress + 'stream/get2dForLiveStream',{headers:headers}),
        referralCode:this.http.get(this.funct.ipaddress + 'stream/getStremReferralCode',{headers:headers}),
        moderninternet:this.http.get(this.funct.ipaddress + 'stream/getStreamModernInternet',{headers:headers}),
        service:this.http.get(this.funct.ipaddress + 'service/listService',{headers:headers}),
      }
    ).pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          console.log(result)
          this.streamDatas=result;
          this.streamDatas.service=result.service.find(service=> service.title=='stream_link')   
          //console.log(this.streamDatas)
        });

  }

  getStremReferralCode(){
    
  }


  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
      });
      this.storage.clear('token');
      this.storage.clear('isUserLoggedIn');
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  ngOnDestroy() {
    document.body.className = "";
  }

}
