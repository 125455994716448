import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'back-component',
  templateUrl: './back-component.component.html',
  styleUrls: ['./back-component.component.scss']
})
export class BackComponentComponent implements OnInit {

  constructor(private route: ActivatedRoute,private router: Router,) { }

  ngOnInit(): void {
  }
  goBack()
  {
    window.history.back();
  }

}
