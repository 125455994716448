

<!-- App Capsule -->
<!-- <div id="appCapsule">  -->
  
    <router-outlet></router-outlet>
<!-- </div> -->
<!-- <div fxFlex="15" id="qr-section">
    <div id="qr-container">
      <qrcode
        [qrdata]="'abcde'"
        [width]="190"
        [errorCorrectionLevel]="'M'"
        [margin]="2"
      ></qrcode>
    </div>
    <div class="top-btn-container">
      <div>
        <button mat-button (click)="saveAsImage('image/png')">
          Download PNG
        </button>
      </div>
      <div>
        <button mat-button (click)="saveAsImage('image/jpeg')">
          Download JPEG
        </button>
      </div>
    </div>
  </div> -->
  
<!-- <app-navigation-bar></app-navigation-bar> -->

