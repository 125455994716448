import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse  } from '@angular/common/http';
import { Router } from '@angular/router';

// rxjs
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

// library
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';

// services
import { DtoService, FunctService } from "@services/index";

@Component({
  selector: 'app-feedback-page',
  templateUrl: './feedback-page.component.html',
  styleUrls: ['./feedback-page.component.css']
})
export class FeedbackPageComponent implements OnInit {
  value!: { value: string };
  feedbackModel : any;
  token : any;
  constructor(private elementRef: ElementRef, private translateService: TranslateService,private router: Router,private toastr: ToastrService, private http: HttpClient, private funct: FunctService, 
    private storage: LocalStorageService, private dto: DtoService) { 

    }

  ngOnInit(): void {
    this.feedbackModel = {
      title : '',
      description : ''
    }
    this.value = { value: "{{ 'feedbackbodyHint' | translate}}" };
  }

  ngOndestroy() {
    this.elementRef.nativeElement.remove();
  }

  handleError(error: HttpErrorResponse){
    if(error.status == 423)
    {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }
    if(error.status == 400)
    {
       this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 404)
    {
       this.toastr.error("Not found.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    if(error.status == 429)
    {
       this.toastr.error("Please contact us.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
    }
    return throwError(error);
    }

  Send()
  {
    this.token = this.storage.retrieve('token');    
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token); 
    if(this.feedbackModel.title == null || this.feedbackModel.title == undefined || this.feedbackModel.title == '')
    {
      this.toastr.error("Please enter title", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
        return;
    }
    if(this.feedbackModel.description == null || this.feedbackModel.description == undefined || this.feedbackModel.description == '')
    {
      this.toastr.error("Please enter description", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
        return;
    }
    this.http.post( this.funct.ipaddress+'feedback/feedbackInsert', this.feedbackModel, { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
     )
      .subscribe(
        result => {
          this.dto.Response = result;
            if(this.dto.Response == true)
            {
              this.toastr.success("Success.", 'Success!', {
                timeOut: 3000,
                positionClass: 'toast-top-right',
                });
              this.router.navigate(["/profile/me-page"],{replaceUrl: true});
            }
        }
      );
  }
}
