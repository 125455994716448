<div class="appHeader text-light" [class]="className">
  <div class="left">
    <a (click)="back()" class="headerButton">
      <ion-icon name="arrow-back"></ion-icon>

    </a>
  </div>
  <div class="pageTitle">
    <ng-content></ng-content>
  </div>
</div>