<appbar (myEvent)="refreshPage()"  >{{'meDetailTap' | translate }}</appbar>

     <div id="appCapsule" class="extra-header-active">      
       
        <div class="container d-flex justify-content-center align-items-center">
            <div class="card">
                
                <div class="upper"><img src="https://i.imgur.com/Qtrsrk5.jpg" class="img-fluid"> </div>
                
                <div class="user text-center">
                    <div class="profile"> 
                      
                        <img src="./assets/img/profile.png" class="rounded-circle" id = "profileImg" *ngIf="userModel.imageUrl == null"> 
                        
                        <img [src]="userModel.imageUrl" class="rounded-circle" width="80" id = "profileImg" *ngIf="userModel.imageUrl != null"> 
                        
                        <label for="file-input">
                            <span class = "cameraIcon"><ion-icon name="camera"></ion-icon></span>
                        </label>

                       <input #file id="file-input" accept="image/*" type="file" 
                        onchange="document.getElementById('profileImg').src = window.URL.createObjectURL(this.files[0])"
                       [(ngModel)]="userModel.imageUrl" [ngModelOptions]="{standalone: true}"
                       (change)="preview(file.files)"/>
                    </div>
                </div>

                <div class="text-center mb-1" style="margin-top: 0px;">
                <div class="row">
                    <div class="col-2 form-group">
                    </div>
                 <div class="col-8 form-group">
                    <input  (keyup.enter)="enter($event)" type="text" class="form-control input" [(ngModel)]="userModel.name" [ngModelOptions]="{standalone: true}">
                 </div>
                 <div class="col-2 form-group">
                </div>
                 </div>
                 <div class="row">
                    <div class="col-2 form-group">
                    </div>
                    <div class="col-8 form-group">
                        <input type="text" class="form-control input" [(ngModel)]="userModel.phone_no" [ngModelOptions]="{standalone: true}" readonly>
                    </div>
                    <div class="col-2 form-group">
                    </div>
                 </div>
                <button class="btn btn-primary btn-sm follow" (click) = "changeProfile()">{{'withdraw_save' | translate}}</button>
                </div>
            </div>
        </div>
     
    </div>
    <!-- * App Capsule -->

    <ngx-spinner bdColor = "rgb(6,56,107)" size = "large" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner>
