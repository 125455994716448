// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  keyApplicationId : '4M259VpbdjqPJ46U7EkCH0R8Qt4awj9sAci56ony',
  keyClientKey: 'W8zrSSVmNwhRRjYhHINgiNeeWsnP03q7SJBgpVJd',
  jsKey:'MyWd0ieW0bK5YQEbdzvhGdKh9JVJc5DBmXlEh04j',
  keyParseServerUrl : 'https://parseapi.back4app.com',
  keyLiveQueryUrl : 'https://happy7788.b4a.io',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
