import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders ,HttpErrorResponse} from '@angular/common/http';
import { Router } from '@angular/router';
import {Location} from '@angular/common';

// library
import { LocalStorageService } from 'ngx-webstorage';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { TranslateService } from '@ngx-translate/core';
import { Md5 } from 'ts-md5';

// rxjs
import { throwError } from 'rxjs';
import 'rxjs/add/operator/map';
declare var require: any;

// services
import { DtoService,FunctService,UtilService } from "@services/index";

@Component({
  selector: 'password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {

  
  showPass: boolean;
  passwordType: any;
  registerModel: any;
  name: any;
  password: any;
  referral_code: any;
  phoneNo: any;
  appVersion: any; 
  confirmPassword: any;

  forGetPasswordModel: any;
  otp_guid: any;
  constructor(private translateService: TranslateService,private toastr: ToastrService, private spinner: NgxSpinnerService, private dto: DtoService, private http: HttpClient, private util: UtilService, 
    private router: Router, private storage: LocalStorageService, private funct: FunctService,private location: Location,) {
     this.showPass= false;
     this.confirmPassword= '';
     this.passwordType= "password";  
     this.otp_guid=history.state.otp_guid;   
     
  }

  ngOnInit(): void {    
    this.registerModel={
      name: '',
      password: '',      
    }
    this.forGetPasswordModel= {
      password: '',
      signature: '',
      phoneNo: '',
      otp_guid: ''
    }
  
  }
    
 
 
  handleError(error: HttpErrorResponse){   
    if(error.status == 0){
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
    }
    
    if(error.status == 423)
    {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        this.storage.clear('token');
        this.storage.clear('isUserLoggedIn');
    }
     if (error.status == 406) {
      // phone number is taken
      this.toastr.error("Tip", 'This mobile is already registered', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
        });
        return false;
     
    }
    
    return throwError(error);
  }
  

  resetPwd() {  
    
    let aaa = this.checkPassword();

    let bb = this.checkConfirmPassword();
  

     
    if (!aaa || !bb ) {
      //console.log("jj");
      return;
    }

    //  this.spinner.show();
    var secretKey = this.funct.secretKey;  
    let headers = new HttpHeaders();
    let phoneNumer = this.storage.retrieve('localPhoneValue');
    var prefix=this.storage.retrieve('localPhonePrefix');   
    if(phoneNumer.startsWith('0'))
    {
      phoneNumer= prefix + phoneNumer.substring(1, phoneNumer.length);
    }
    else{
      phoneNumer= prefix + phoneNumer;
    }
    var text=secretKey + phoneNumer + this.password;
    this.forGetPasswordModel.signature = Md5.hashStr(text).toString();
    this.forGetPasswordModel.phoneNo=phoneNumer;   
    this.registerModel.phone_no = phoneNumer; //AdminDTO
    this.registerModel.password = this.password;
    this.registerModel.referral_code = '';     
    this.registerModel.appVersion = require('@baseUrl/package.json').version; 
    this.forGetPasswordModel.password= this.funct.encrypt(this.password);
    this.forGetPasswordModel.otp_guid=this.otp_guid; 
    ///user/setNewPassword?phoneNo=$phoneNo&password=$password";
    //console.log(this.forGetPasswordModel)
     this.http.post( this.funct.ipaddress+'user/setNewPassword', this.forGetPasswordModel , { headers: headers }).subscribe(
       result => {
         
         this.dto.Response = result;    
         if (this.dto.Response == true) {
             this.router.navigate(['/account/login'],{replaceUrl: true});
             return true;
           
          
         }
          else {
            this.toastr.error("Tip", this.dto.Response.message.toString(), {
              timeOut: 3000,
              positionClass: 'toast-top-right',
              });            
          
            return false;
          }
           
        
       }
     );
    
   
  }
  
  checkPassword()
  {   
    
    $("#passwordErr").html('');
        if(!this.password||this.password.length < 4)
        {
          $("#passwordErr").html(this.translateService.instant("reqPassLength"));
          return false;
        }
        if(this.password&&this.password.length == 4)
        {
          $("#passwordErr").html("");
           return true;
        }
        if(this.password == '' ||this.password == null || this.password == undefined) 
        {
           var passwordRequired = this.translateService.instant("requiredFiled");
          passwordRequired  =  passwordRequired .toString().replace("{{value}}", this.translateService.instant("passwordHint"));
          $("#passwordErr").html(passwordRequired);
           return false;
        }
    return true;
  }
  checkConfirmPassword(){ 
    $("#confirmPasswordErr").html('');
    if (this.password&& this.password.length == this.confirmPassword.length && this.password == this.confirmPassword) {
    return true;
    }
    else {
     
         $("#confirmPasswordErr").html(this.translateService.instant("confirmPassIncorrect"));
     return false;
    }
     return true;

  }

  showPassword(show: boolean){
    this.showPass = show;
    if(show){
      this.passwordType= "text"; 
    }
    else{
      this.passwordType= "password"; 
    }
    
  }
}
