import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from 'ngx-webstorage';
import { catchError, retry } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FunctService } from './funct.service';
import { DtoService } from './dto.service';
import { Subject } from 'rxjs';
import { Column } from '@baseUrl/src/app/shared/basic-components/column';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  isLogged: any;
  gameProviderList: any;
  token: any;
  userProfileBalance: any;
  mainWallet: any;
  subject = new Subject<string>();

  constructor(private funct: FunctService, private toastr: ToastrService, private http: HttpClient, private dto: DtoService, private storage: LocalStorageService,) {
    // this.gameProviderList = this.getGameProviderList();
    // this.mainWallet = this.getUserProfile();
  }

  ngOnInit(): void {

  }

  handleError(error: HttpErrorResponse) {
    if (error.status == 423) {
    }
    if (error.status == 400) {
      this.toastr.error("Bad request.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-right',
      });
    }
    return throwError(error);
  }

  getGameProviderList() {
    let headers = new HttpHeaders();
    // this.localgameProviderList = this.storage.retrieve('localgameProviderList');
    /* if(this.localgameProviderList != null)
     {
       this.gameProviderList = this.localgameProviderList;
       this.spinner.hide();
       return;
     }*/
    this.http.get(this.funct.ipaddress + 'gameProvider/getGameProviderList', { headers: headers })
      .pipe(
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.gameProviderList = this.dto.Response;
          this.storage.store('localgameProviderList', this.gameProviderList);
        }
      );
  }
  /*get user porfile*/
  getUserProfile() {
    let params = new HttpParams();
    this.token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', this.token);
    this.http.get(this.funct.ipaddress + 'user/UserProfile', { headers: headers })
      .pipe
      (
        catchError(this.handleError.bind(this))
      )
      .subscribe(
        result => {
          this.dto.Response = {};
          this.dto.Response = result;
          this.userProfileBalance = this.dto.Response.balance;
          this.storage.store('userMainWallet', this.userProfileBalance);
        }
      );
  }
  getCloseDateTime() {
    let headers = new HttpHeaders();
    return this.http.get(this.funct.ipaddress + 'threedconfig/3d_close_time', { headers: headers });
  }

  getMyUsers(FromDate: string, ToDate: string, referral_code: string): Observable<any> {
    let url = `${this.funct.ipaddress}agent/GetAgentCommession?FromDate=${FromDate}&ToDate=${ToDate}&referral_code=${referral_code}`
    return this.http.get<any>(url)
  }

  getTopUpUsers(FromDate: string, ToDate: string, phno: string): Observable<Column[]> {
    let token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    let url = `${this.funct.ipaddress}agent/GetTopupUsers?FromDate=${FromDate}&ToDate=${ToDate}&phone_no=${phno}`
    return this.http.get<Column[]>(url, { headers: headers })
  }

  getNoTopUpUsers(FromDate: string, ToDate: string, phno: string): Observable<Column[]> {
    let token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    let url = `${this.funct.ipaddress}agent/GetNoTopupUsers?FromDate=${FromDate}&ToDate=${ToDate}&phone_no=${phno}`
    return this.http.get<Column[]>(url, { headers: headers })
  }

  getTopupUsersCount(phno: string) {
    let token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    let url = `${this.funct.ipaddress}agent/GetTopupUserscount?phone_no=${phno}`
    return this.http.get<number>(url, { headers: headers })
  }

  getNoTopupUsersCount(phno: string) {
    let token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    let url = `${this.funct.ipaddress}agent/GetNoTopupUserscount?phone_no=${phno}`
    return this.http.get<number>(url, { headers: headers })
  }
  
  getMyLevel(userId: string): Observable<any> {
    let token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    let url = `${this.funct.ipaddress}level/Getvip?userid=${userId}`;
    return this.http.get<any>(url, { headers: headers })
  }

  getVIPByUserId(userId: string): Observable<any> {
    let token = this.storage.retrieve('token');
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', token);
    let url = `${this.funct.ipaddress}level/getviplistbyuserid?id=${userId}`;
    return this.http.get<any>(url, { headers: headers })
  }
}
