import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
  providedIn: 'root'
})
export class SignalrService {
  public data: any;
  public signalrConnect: any;
  public signalrInfo: any;
  constructor(private http: HttpClient,private storage:LocalStorageService) {
    //pro
    this.signalrConnect= "https://signalr.lucky2d.com/chatHub";
    this.signalrInfo = "https://signalr.lucky2d.com/api/info";
    //dev
    // this.signalrConnect= "https://demosignalr.lucky2d.com/chatHub";
    // this.signalrInfo =  "https://demosignalr.lucky2d.com/api/info";    
  }


  private hubConnection: signalR.HubConnection
  public startConnection = () => {

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.signalrConnect)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        this.http.get(this.signalrInfo)
          .subscribe((res: any) => {
          
          })
      })
      .catch(err => console.log('Error while starting connection: ' + err))
  }

  public addTransferChartDataListener = () => {
    this.hubConnection.on('transferchartdata', (data) => {
      this.data = JSON.parse(data).results[0];
      this.storage.store('sectionResultList', this.data);
    });
  }
}
