import { Component, ComponentRef } from '@angular/core';
import { IModalDialog, IModalDialogOptions } from 'ngx-modal-dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-game-maintance',
  templateUrl: './game-maintance.component.html',
  styleUrls: ['./game-maintance.component.scss']
})
export class GameMaintanceComponent implements IModalDialog {
  public message:any;
  private internalActionButtons = []; 
  constructor(private translateService: TranslateService){

  }

  dialogInit(reference: ComponentRef<IModalDialog>, options: Partial<IModalDialogOptions<string>>) {
    options.actionButtons = this.internalActionButtons;
    this.message=options.data;
options.title='Information';
    this.internalActionButtons.push({
      text: this.translateService.instant('close'),
      buttonClass: 'btn',
      onAction: () => true     
    });

    
  }
 
}