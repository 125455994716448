import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse  } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

// rxjs
import { catchError, retry } from 'rxjs/operators';
import { throwError } from 'rxjs';

// library
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';

// services
import { DtoService, FunctService } from "@services/index";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  password : any;
  showPass: boolean;
  passwordType: any;
  newPassword : any;
  confirmPassword : any;
  changePasswordModel : any;
  token : any;
  loadingSubmiting: any;
  constructor(private spinner: NgxSpinnerService,private translateService: TranslateService,private router: Router,private toastr: ToastrService, private http: HttpClient, private funct: FunctService, 
    private storage: LocalStorageService, private dto: DtoService) {

     
     }

  ngOnInit(): void {
    this.passwordType = "password";
    this.changePasswordModel ={
      oldPassword : '',
      newPassword : '',
      signature: ''
    }
  }
 
  checkPassword()
  {
    $(".passError1").html("");

   if(this.password == '' || this.password == undefined || this.password == null)
   {
     $(".passError1").html(this.translateService.instant("curretPassisRequired"));
     return false;
   }
   else
   {
    $(".passError1").html("");
    return true;
   }
  }

  checkNewPassword()
  {
    $(".passError2").html("");

    if(this.newPassword == '' || this.newPassword == undefined || this.newPassword == null)
      {
        $(".passError2").html(this.translateService.instant("newPassisRequired"));
        return false;
      }
      
    if(this.newPassword != '' || this.newPassword != undefined || this.newPassword != null)
    {
        if(this.newPassword.length < 4)
        {
          $(".passError2").html(this.translateService.instant("reqPassLength"));
          return false;
        }
        if(this.newPassword.length == 0)
        {
          $(".passError2").html(this.translateService.instant("newPassisRequired"));
          return false;
        }
        else
        {
          $(".passError2").html("");
          return true;
        }
        
    }
    else
      {
        $(".passError2").html("");
        return true;
      }
  }

  checkConfirmPassword()
  {
    $(".passError3").html("");

    if(this.confirmPassword == '' || this.confirmPassword == undefined || this.confirmPassword == null)
    {
      $(".passError3").html(this.translateService.instant("confirmPassLength"));
      return false;
    }
    if(this.confirmPassword != this.newPassword)
    {
        $(".passError3").html(this.translateService.instant("confirmPassIncorrect"));
        return false;
    }
    else
    {
      $(".passError3").html("");
      return true;
    }
    
  }

  showPassword(show: boolean){
    this.showPass = show;
    if(this.showPass == true){
      this.passwordType= "text"; 
    }
    else{
      this.passwordType= "password"; 
    }
  }

  handleError(error: HttpErrorResponse){
    
    this.loadingSubmiting=false;
    this.spinner.hide('loadingSubmiting');
    if(error.status == 0){
      this.toastr.error("", 'check your internet connection', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        return;
    }
    
    if(error.status == 423)
    {
      this.toastr.error("", this.translateService.instant("youNeedLogin"), {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        this.storage.clear('token');
        this.storage.clear('isUserLoggedIn');
        return;
    }
    if(error.status == 400)
    {
       this.toastr.error(this.translateService.instant("current_password_wrong"), '', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        return;
    }
    if(error.status == 404)
    {
       this.toastr.error("User not found.", 'Invalid!', {
        timeOut: 3000,
        positionClass: 'toast-top-center',
        });
        return;
    }
    this.toastr.error("", error.status.toString(), {
      timeOut: 3000,
      positionClass: 'toast-top-center',
      });
      return;
    //return throwError(error);
    }

  changePassword()
  {
    if(this.loadingSubmiting!=null && this.loadingSubmiting == true){
          return;
        }  
    var chk1=this.checkPassword();
    var chk2=this.checkNewPassword();
    var chk3=this.checkConfirmPassword();
    if(!chk1 || !chk2 || !chk3){
      return;
    }
  // this.changePasswordModel.oldPassword = this.password;
  // this.changePasswordModel.newPassword = this.newPassword;
  var secretKey = this.funct.secretKey;  
  var text=this.password + this.newPassword + secretKey;
  
  this.changePasswordModel.signature = this.funct.getSignature(text);
  this.changePasswordModel.oldPassword= this.funct.encrypt(this.password);
  this.changePasswordModel.newPassword= this.funct.encrypt(this.newPassword);  
 
  this.token = this.storage.retrieve('token');    
  let headers = new HttpHeaders();
  headers = headers.set('Authorization', this.token); 
  this.loadingSubmiting=true;
  this.spinner.show('loadingSubmiting');
  this.http.post( this.funct.ipaddress+'user/updatePassword', this.changePasswordModel, { headers: headers })
    .pipe(
      catchError(this.handleError.bind(this))
   )
    .subscribe(
      result => {
        this.dto.Response = result;
          if(this.dto.Response == true)
          {
            this.loadingSubmiting=false;
            this.spinner.hide('loadingSubmiting');
            this.toastr.success("Success.", 'Success!', {
              timeOut: 3000,
              positionClass: 'toast-top-center',
              });
              this.storage.clear('isUserLoggedIn');
              this.storage.clear('token');
              this.router.navigate(["/account/login"],{replaceUrl: true });
          }
      }
    );    
  }


  enter(event)
  {
    event.target.blur();
  }
}
