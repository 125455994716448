import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '@baseUrl/src/app/service';
import { interval, Observable } from 'rxjs';
import { flatMap, map, mergeMap, take, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-system-maintenece',
  templateUrl: './system-maintenece.component.html',
  styleUrls: ['./system-maintenece.component.scss']
})
export class SystemMainteneceComponent implements OnInit {
  public errorMessage: any;

  constructor(private _route: ActivatedRoute, private _commonService: CommonService, private _router: Router) {

  }

  ngOnInit(): void {
    this.errorMessage = this._route.snapshot.paramMap.get('message');
    this.refreshPage();
  }

  refreshPage() {
    interval(2*60*1000).subscribe(x => {
      this._commonService.getCloseDateTime()
        .subscribe((res) => {
          this._router.navigate(['home']);
        })
    });

  }

  checkX(x) {
    console.log(x)
  }
}
