import { Injectable } from '@angular/core';
import { 
  HttpEvent, HttpRequest, HttpHandler, 
  HttpInterceptor, HttpResponse 
} from '@angular/common/http';
import { Observable} from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(private _router:Router){}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     let response: any;    
     return next.handle(request)   
     .pipe(
      tap({
        // Succeeds when there is a response; ignore other events
        next: (event) => (response = event instanceof HttpResponse ? event : ''),
        // Operation failed; error is an HttpErrorResponse
        error: (error) => (response = 'failed')
      }),
      // Log when response observable either completes or errors
      finalize(() => {
       //alert(JSON.stringify(response))
       //&& response.error.mtype == "frontend"
       if(response.status == 405){
        this._router.navigate(['sytem-maintence',response.error.Message,{state: {message: response.error.Message}}]);
       }   
      })
    );
    // .pipe(

    //   retry(1),

    //   catchError((error: HttpErrorResponse) => {
    //     console.log("reach here");
    //     let errorMessage = '';

    //     if (error.error instanceof ErrorEvent) {

    //       // client-side error
    //         console.log('clent error');

    //     } else {
    //       if(error.status==405){
    
    //         this._router.navigate(['sytem-maintence',error.error.Message,{state: {message: error.error}}]);
    //       }
   
    //       // server-side error
    //       // console.log('server error',error)
    //     //   errorMessage = `Message: ${ error.error.message ? error.error.message : error.message}`; 
    //     }

    //     // window.alert(errorMessage);

    //     return throwError(error);

    //   })

    // )
  }
}