
<!-- <footer> -->
    <div class="appBottomMenu">
        <a (click)="openPage('/home')" class="item"
            [class]="(routerName=='/home' || routerName=='/home/'+deviceId) ? 'active':''">
            <div class="col">
                <ion-icon name="{{routerName=='/home' || routerName=='/home/'+deviceId ? 'home' : 'home-outline'}}"></ion-icon>
                <span class="active-title">{{ 'homeTap' | translate}}</span>
            </div>
    
        </a>
        <a (click)="openPage('/wallet')" class="item" [class]="routerName=='/wallet'? 'active':''"
            *ngIf="isUserLoggedIn">
            <div class="col">
                <ion-icon name="{{routerName=='/wallet' ? 'wallet' : 'wallet-outline'}}"></ion-icon>
                <span class="active-title">{{ 'walletTap' | translate}}</span>
            </div>
        </a>
    
        <a (click)="openPage('/promotion/list')" class="item" [class]="routerName=='/promotion/list'? 'active':''"
            *ngIf="isUserLoggedIn">
            <div class="col">
                <ion-icon name="{{routerName=='/promotion/list' ? 'megaphone' : 'megaphone-outline'}}"></ion-icon>
                <!-- <span class="badge badge-danger">5</span> -->
                <span class="active-title">{{ 'promotionTap' | translate}}</span>
            </div>
        </a>
        <a (click)="openPage('/service/service-phone')" class="item"
            [class]="routerName=='/service/service-phone'? 'active':''">
            <div class="col">
                <ion-icon name="{{routerName=='/service/service-phone'  ? 'call' : 'call-outline'}}"></ion-icon>
                <span class="active-title">{{ 'serviceTap' | translate}}</span>
            </div>
        </a>
        <a (click)="openPage('/profile/me-page')" class="item" [class]="routerName=='/profile/me-page'? 'active':''">
            <div class="col">
                <ion-icon name="{{routerName=='/profile/me-page'  ? 'person' : 'person-outline'}}"></ion-icon>
                <span class="active-title">{{ 'meTap' | translate}}</span>
            </div>
        </a>
    
    </div>
<!-- </footer> -->
