
    <app-not-refresh-appbar parentLink="/account/forget-password" [rootLevel]=1>{{'forgetPwdStepOne' | translate }}</app-not-refresh-appbar>
    
     <!-- App Capsule -->
     <div id="appCapsule" class="extra-header-active">               
        <div class="section full mt-1 mx-3">
            
            <form>
               
                <div class="section-title mb-2 text-success">{{ 'forget_password_dec1' | translate}}</div>            
                
                <div class="col"> 
                    <div class="passwrod-eye">
                        <input [type]="passwordType" class="form-control"  placeholder="{{ 'regpasswordHint' | translate}}"  [(ngModel)]="password" [ngModelOptions]="{standalone: true}" (keyup) ="checkPassword()">
                        <span class="text-eye">
                            <a  (click)="showPassword(!showPass)"><ion-icon [attr.name]="showPass ? 'eye' : 'eye-off' "></ion-icon></a>
                        </span>  
                    </div>                 
                                    
                    <p id ="passwordErr" class="error-color"></p>
                </div>
                <div class="col"> 
                    <div class="passwrod-eye">
                        <input [type]="passwordType" class="form-control"  placeholder="{{ 'regconfirmpasswordHint' | translate}}" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}" (keyup)=" checkConfirmPassword()">
                        <span class="text-eye">
                            <a  (click)="showPassword(!showPass)"><ion-icon [attr.name]="showPass ? 'eye' : 'eye-off' "></ion-icon></a>
                        </span>  
                    </div>                                  
                    <p id = "confirmPasswordErr" class="error-color"></p>
                </div>
                <div class="text-center">
 <button type="button" class="button" (click)="resetPwd()">{{ 'confirm' | translate}}</button>
                </div>
               

               
            </form>
        </div>

    </div>
    <!-- * App Capsule -->

    <!-- <ngx-spinner bdColor = "rgb(6,56,107)" size = "large" color = "#fff" type = "ball-spin-clockwise" [fullScreen] = "true"></ngx-spinner> -->
